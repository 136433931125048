import React, { useState } from 'react';
import {
    Autocomplete,
    TextField,
    Checkbox,
    Box,
    IconButton,
    Chip,
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import statesAndCities from '@/assets/statesAndCities.json'


interface Props {
    value: string[];
    onChange: (value: string[]) => void;
    onStarCity?: (value: string) => void;
    starredCity?: string;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CitySearchSelect: React.FC<Props> = ({
    value,
    onChange,
    onStarCity
}) => {
    const [inputValue, setInputValue] = useState('');

    const cities = statesAndCities.flatMap(state =>
        state.cities.map(cityName => `${cityName} (${state.initials})`)
    );

    const handleStarClick = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        value: string
    ) => {
        event.stopPropagation();
        if (onStarCity) {
            onStarCity(value);
        }
    };

    return (
        <Autocomplete
            size='small'
            multiple
            id="city-select"
            options={cities}
            disableCloseOnSelect
            blurOnSelect={false}
            value={value}
            onChange={(_, newValue) => {
                onChange(newValue)
            }}
            inputValue={inputValue}
            onInputChange={(_, newInputValue, reason) => {
                if (reason === 'reset') return
                setInputValue(newInputValue);
            }}
            getOptionLabel={(option) => option}
            isOptionEqualToValue={(option, value) => option === value}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => {
                    const { key, ...tagProps } = getTagProps({ index });
                    return (
                        <Chip
                            size='small'
                            key={option}
                            label={option}
                            {...tagProps}
                        />
                    );
                })
            }
            renderOption={(props, option, { selected }) => {
                const { key, ...otherProps } = props as any;
                return (
                    <li key={option} {...otherProps} >
                        {selected}
                        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    checked={selected}
                                    sx={{ mr: 1 }}
                                />
                                <Box>
                                    {option}
                                </Box>
                            </Box>
                            <IconButton
                                size="small"
                                onClick={(e) => handleStarClick(e, option)}
                                sx={{ ml: 1 }}
                            >
                                <StarIcon
                                    sx={{
                                        color: value.length > 0 && value[0] === option ? '#FFD700' : '#ccc',
                                        fontSize: '20px'
                                    }}
                                />
                            </IconButton>
                        </Box>
                    </li>
                )
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label="Cidades de atuação"
                    placeholder="Buscar cidade..."
                />
            )}
            filterOptions={(options, { inputValue }) => {
                const normalized = inputValue.toLowerCase();
                return options.filter(option =>
                    option.toLowerCase().includes(normalized)
                ).slice(0, 70);
            }}
        />
    );
};

export default CitySearchSelect;