import React, { useState } from 'react'

import {
    Visibility as VisibilityIcon,
    VisibilityOff as VisibilityOffIcon,
    Check as CheckIcon,
    FileCopy as FileCopyIcon
} from '@mui/icons-material'
import { Button, Dialog, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material'

interface IShowUserPasswordModalProps {
    isOpenPasswordModal: boolean
    setIsOpenPasswordModal: (value: boolean) => void
    password: string
    setPassword: (value: string) => void
}

export const ShowUserPasswordModal: React.FC<IShowUserPasswordModalProps> = ({ isOpenPasswordModal, setIsOpenPasswordModal, password, setPassword }) => {
    const [hidden, setHidden] = useState(true)
    const [copied, setCopied] = useState(false)

    const handleCopy = (text: string) => {
        navigator.clipboard.writeText(text).then(() => {
            setCopied(true)
            setTimeout(() => setCopied(false), 2000)
        })
    }

    return (
        <Dialog
            open={isOpenPasswordModal}
            onClose={() => { setIsOpenPasswordModal(false); setPassword('') }}
        >
            <DialogTitle>Senha Gerada</DialogTitle>
            <DialogContent>
                <Grid
                    container
                    className="dialogGridContent"
                    alignContent="center"
                    justifyContent="center"
                >
                    <Typography>
                        Atenção! Você está visualizando uma senha gerada
                        aleatoriamente que não poderá ser exibida
                        novamente. Para alterar a senha, é preciso
                        solicitar a redefinição.
                    </Typography>

                    <Grid item xs={12} style={{ marginTop: 16 }}>
                        <FormControl variant="outlined" fullWidth>
                            <TextField
                                id="password_generated_field"
                                variant="outlined"
                                label="Senha"
                                type={hidden ? 'password' : 'text'}
                                size="small"
                                disabled
                                value={password}
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="Copia senha para área de trabalho"
                                                onClick={() => {
                                                    if (password) {
                                                        handleCopy(password)
                                                    }
                                                }}
                                                edge="end"
                                            >
                                                {copied
                                                    ? (
                                                        <CheckIcon />
                                                    )
                                                    : (
                                                        <FileCopyIcon />
                                                    )}
                                            </IconButton>

                                            <IconButton
                                                aria-label="Torna senha visível"
                                                onClick={() => {
                                                    setHidden(!hidden)
                                                    setTimeout(() => setHidden(true), 5000)
                                                }}
                                                edge="end"
                                            >
                                                {hidden
                                                    ? (
                                                        <VisibilityOffIcon />
                                                    )
                                                    : (
                                                        <VisibilityIcon />
                                                    )}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </FormControl>
                    </Grid>

                    <Divider style={{ margin: '16px 10px' }} />

                    <Button
                        style={{ marginTop: 20 }}
                        color="primary"
                        variant="contained"
                        onClick={() => setIsOpenPasswordModal(false)}
                        autoFocus
                    >
                        Fechar
                    </Button>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}
