import React from 'react';
import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

interface ApiKeyExpirationProps {
    minutes: number;
}

interface TimeUnit {
    value: number;
    singular: string;
    plural: string;
}

const createTimeParts = (duration: duration.Duration): TimeUnit[] => {
    return [
        {
            value: Math.floor(duration.asYears()),
            singular: 'ano',
            plural: 'anos'
        },
        {
            value: Math.floor(duration.asMonths() % 12),
            singular: 'mês',
            plural: 'meses'
        },
        {
            value: Math.floor(duration.asDays() % 30),
            singular: 'dia',
            plural: 'dias'
        },
        {
            value: Math.floor(duration.asHours() % 24),
            singular: 'hora',
            plural: 'horas'
        },
        {
            value: Math.floor(duration.minutes() % 60),
            singular: 'minuto',
            plural: 'minutos'
        }
    ];
};

const formatDetailedExpiration = (minutes: number, showZeroAsInfinite: boolean = true): string => {
    if (minutes === 0) {
        return showZeroAsInfinite ? 'Sem expiração' : '0 minutos';
    }

    const durationObj = dayjs.duration(minutes, 'minutes');
    const timeParts = createTimeParts(durationObj);

    const parts = timeParts
        .filter(part => part.value > 0)
        .map(part => `${part.value} ${part.value === 1 ? part.singular : part.plural}`);

    if (parts.length === 0) {
        return 'Menos de 1 minuto';
    }

    if (parts.length === 1) {
        return parts[0];
    }

    const lastPart = parts.pop();
    return `${parts.join(', ')} e ${lastPart}`;
};

const ApiKeyExpirationTooltip: React.FC<ApiKeyExpirationProps> = ({ minutes }) => {
    const expirationDescription = formatDetailedExpiration(minutes);
    return (
        <Typography variant="body2" sx={{ color: 'text.secondary', marginTop: .5, fontSize: 12, textAlign: 'start' }}>
            {expirationDescription}
        </Typography>
    );
};

export default ApiKeyExpirationTooltip;