import axios from 'axios'

import ApiConfig from '@/config/api'

const ApiService = axios.create({
    baseURL: ApiConfig.apiUrl
})

ApiService.interceptors.request.use((config: any) => {
    config.baseURL = ApiConfig.apiUrl
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`

    const urls = config?.url?.split('|')!

    if (urls[0] === 'campaign') config.url = `${ApiConfig.campaignApiUrl}/${urls[1]}`
    else if (urls[0] === 'metrics') config.url = `${ApiConfig.metricsApiUrl}/${urls[1]}`
    else config.url = `${ApiConfig.apiUrl}${urls[0]}`

    return config
})

ApiService.interceptors.response.use((config: any) => { return config }, (error: any) => {
    if (error && error.response && error.response.status !== 200) return Promise.reject(error.response)
})

export default ApiService
