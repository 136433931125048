import dayjs from 'dayjs'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import NumberFormat from 'react-number-format'
import { useParams } from 'react-router-dom'

import Divider from '@/components/Divider'
import { useFetch } from '@/hooks/useFetch'
import useDidMount from '@/hooks/useMount'
import ApiService from '@/services/Api'
import {
    EditOutlined as EditIcon,
    CloseOutlined as CloseIcon,
} from '@mui/icons-material'
import {
    Grid,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Button,
    TextField,
    Dialog,
    DialogTitle,
    DialogActions,
    CircularProgress,
    FormControlLabel,
    Switch,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material'

import useStyles from './styles'

interface ICompany {
    uuid: string
    name: string
    corporateName: string
    streamId: number
}

export interface ICampaign {
    uuid?: string
    title: string
    description?: string
    enabled: boolean
    beginAt: string
    finishAt: string
    companies?: ICompany[]
    companyUuids?: string[]
    createdAt?: string
    updatedAt?: string
}

export interface IAdvertisement {
    uuid?: string
    campaignUuid: string
    title: string
    description?: string
    enabled: boolean
    quantity: number
    quantityDistributed?: number
    duration?: number
    price: number
    bitrate?: string
    audioBase64?: string
    bannerBase64?: string | null;
    bannerUrl?: string
    bannerButtonText?: string | null
    bannerHref?: string | null
    createdAt?: string
    updatedAt?: string
    audioFrequency?: number
    audioFormat?: string
    type: string

}

interface ICustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const NumberFormatCustom = React.forwardRef<NumberFormat<string>, ICustomProps>(
    function NumberFormatCustom(props, ref) {
        const { onChange, ...other } = props

        return (
            <NumberFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value
                        }
                    })
                }}
                isAllowed={(values) => {
                    const { value } = values
                    return value.split('.')[0].length === 1 || value[0] !== '0'
                }}
                isNumericString
                prefix="R$"
                decimalSeparator=","
                allowedDecimalSeparators={['.']}
                decimalScale={2}
                fixedDecimalScale
            />
        )
    }
)

const Advertisement: React.FC = () => {
    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar()

    const params: any = useParams()
    const { campaignUuid } = params

    const [campaign, setCampaign] = useState<ICampaign>({} as ICampaign)
    const [advertisements, setAdvertisements] = useState<IAdvertisement[]>([])
    const [advertisement, setAdvertisement] = useState<IAdvertisement>({} as IAdvertisement)
    const [uploadedBanner, setUploadedBanner] = useState<File | undefined>()
    const [uploadedFileName, setUploadedFileName] = useState<string>('')
    const [openedAdvertisementDialog, setOpenedAdvertisementDialog] = useState<boolean>(false)
    const [loadingAdvertisement, setLoadingAdvertisement] = useState<boolean>(false)
    const [searchInput, setSearchInput] = useState<string>('')

    const openAdvertisementDialog = (advertisement?: IAdvertisement) => {
        setAdvertisement(advertisement ?? {
            campaignUuid,
            enabled: true,
            price: 0.00
        } as IAdvertisement)
        setUploadedFileName('')
        setUploadedBanner(undefined)
        setOpenedAdvertisementDialog(true)
    }

    const handleCloseAdvertisementDialog = () => {
        setOpenedAdvertisementDialog(false)
    }

    const handleChangeAdvertisementEnabled = async (advertisement: IAdvertisement) => {
        try {
            setAdvertisements(advertisements.map(ad => ({
                ...ad,
                enabled: ad.uuid === advertisement.uuid ? !advertisement.enabled : ad.enabled
            })))

            await ApiService.put(`campaign|intranet/campaigns/${campaignUuid}/advertisements/${advertisement.uuid}`, {
                enabled: !advertisement.enabled
            })

            getAdvertisements()
        } catch (error: any) {
            enqueueSnackbar(error?.data as string ?? 'Ocorreu um erro ao criar a propaganda.', {
                variant: 'error'
            })
        }
    }

    const getAdvertisements = async () => {
        const { data } = await ApiService.get(`campaign|intranet/campaigns/${campaignUuid}/advertisements`) // IAdvertisement[]

        setAdvertisements(data)
    }

    const getCampaign = async () => {
        const { data } = await ApiService.get(`campaign|intranet/campaigns/${campaignUuid}`) // ICampaign

        setCampaign(data)
    }

    const saveAdvertisement = async () => {

        try {
            if ((advertisement.bannerBase64 || advertisement.bannerUrl) && (!advertisement.bannerButtonText || !advertisement.bannerHref)) {
                enqueueSnackbar('Por favor, informe o texto do botão e o link vinculados ao banner.', {
                    variant: 'error'
                })
                return
            }


            setLoadingAdvertisement(true)


            const path = `campaign|intranet/campaigns/${campaignUuid}/advertisements`

            if (advertisement.uuid) await ApiService.put(path + `/${advertisement.uuid}`, advertisement)
            else await ApiService.post(path, advertisement)

            handleCloseAdvertisementDialog()
        } catch (error: any) {
            enqueueSnackbar(error?.data as string ?? 'Ocorreu um erro ao criar a propaganda.', {
                variant: 'error'
            })
        }

        getAdvertisements()

        setLoadingAdvertisement(false)
    }

    useDidMount(getCampaign)

    useFetch<IAdvertisement[]>(
        `campaign|intranet/campaigns/${campaignUuid}/advertisements`,
        (data: IAdvertisement[]) => {
            setAdvertisements(data)
        }
    )

    const handleSelectAudio = async (event: any) => {
        try {
            const fileData = event.target.files[0]

            const audioBase64: string = await new Promise((resolve) => {
                const reader = new FileReader()
                reader.readAsDataURL(fileData)

                reader.onloadend = (evt) => {
                    if (evt?.target?.readyState === FileReader.DONE) {
                        resolve(evt?.target?.result as string)
                    }
                }
            })

            setUploadedFileName(fileData.name)

            setAdvertisement({
                ...advertisement,
                audioBase64
            })
        } catch (e) {
            enqueueSnackbar('Ocorreu um erro ao tentar fazer upload do audio.', { variant: 'warning' })
        }
    }

    const handleSelectBanner = async (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
            const file = event.target.files?.[0];
            if (!file) return;

            const validFormats = ['image/png', 'image/jpeg', 'image/jpg'];
            if (!validFormats.includes(file.type)) {
                enqueueSnackbar('Formato inválido. Apenas PNG, JPEG e JPG são permitidos.', { variant: 'warning' });
                return;
            }

            const img = new Image();
            img.src = URL.createObjectURL(file);

            img.onload = async () => {
                const { width, height } = img;

                if (width > height) {
                    enqueueSnackbar('A largura da imagem deve ser menor que a altura.', { variant: 'warning' });
                    return;
                }

                if (width > 1080 || height > 1920) {
                    enqueueSnackbar('A imagem excede o tamanho máximo de 1080x1920.', { variant: 'warning' });
                    return
                }

                const fileBase64: string = await new Promise((resolve) => {
                    const reader = new FileReader()
                    reader.readAsDataURL(file)

                    reader.onloadend = (evt) => {
                        if (evt?.target?.readyState === FileReader.DONE) {
                            resolve(evt?.target?.result as string)
                        }
                    }
                })

                setUploadedBanner(file)
                setAdvertisement({
                    ...advertisement,
                    bannerBase64: String(fileBase64)
                });
            };
        } catch (error) {
            enqueueSnackbar('Ocorreu um erro ao tentar fazer upload da imagem.', { variant: 'warning' });
        }
    };

    const handleRemoveBanner = (e: React.MouseEvent) => {
        e.stopPropagation();
        setUploadedBanner(undefined);
        setAdvertisement({
            ...advertisement,
            bannerBase64: null,
            bannerUrl: undefined,
            bannerButtonText: null,
            bannerHref: null
        });
    };

    return (
        <>
            <Grid container justifyContent="space-between">
                <TextField
                    label="Buscar propaganda" size="small" color="primary" variant="outlined"
                    value={searchInput}
                    onChange={({ target }) => setSearchInput(target.value)}
                />
                <Button color="primary" variant="contained" onClick={() => openAdvertisementDialog()}>
                    Criar nova propaganda
                </Button>
            </Grid>

            <Divider size={3} />

            <Grid container>
                <TableContainer component={Paper} className={classes.table}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Título</TableCell>
                                <TableCell align="right">Descrição</TableCell>
                                <TableCell align="right">Veiculações contratadas</TableCell>
                                <TableCell align="right">Veiculado</TableCell>
                                <TableCell align="right">Propaganda Ativa</TableCell>
                                <TableCell align="left">Tipo</TableCell>
                                <TableCell align="right">Duração</TableCell>
                                <TableCell align="right">Criado em</TableCell>
                                <TableCell align="right">Atualizado em</TableCell>
                                <TableCell align="right">Ações</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                advertisements.filter(advertisement => (!advertisement?.title && !searchInput) || advertisement?.title?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(searchInput.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')))
                                    .map((advertisement) => (
                                        <TableRow key={advertisement?.uuid}>
                                            <TableCell component="th" scope="row">{advertisement?.title}</TableCell>
                                            <TableCell align="right" size="small" className={classes.descriptionColumn}>{advertisement?.description}</TableCell>
                                            <TableCell align="right">{advertisement?.quantity}</TableCell>
                                            <TableCell align="right">{advertisement?.quantityDistributed}</TableCell>
                                            <TableCell align="right">
                                                <Switch checked={advertisement?.enabled} onClick={() => handleChangeAdvertisementEnabled(advertisement)} color="primary" />
                                            </TableCell>
                                            <TableCell align="left">
                                                {advertisement?.type ? advertisement.type.charAt(0).toUpperCase() + advertisement.type.slice(1) : ''}
                                            </TableCell>
                                            <TableCell align="right">{+advertisement?.duration!}s</TableCell>
                                            <TableCell align="right">{dayjs(advertisement?.createdAt).format('DD/MM/YYYY HH:mm')}h</TableCell>
                                            <TableCell align="right">{dayjs(advertisement?.updatedAt).format('DD/MM/YYYY HH:mm')}h</TableCell>
                                            <TableCell align="right">
                                                <IconButton
                                                    onClick={() => openAdvertisementDialog(advertisement)}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

            <Dialog open={openedAdvertisementDialog}>
                <DialogTitle className={classes.dialogTitle}>
                    {!advertisement?.uuid ? 'Criando nova ' : 'Editando a '}propaganda
                </DialogTitle>
                <Grid container className={classes.dialogContainer} >
                    <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                        <TextField
                            id="advertisement_title_field"
                            className={classes.itemInput}
                            label="Título" size="small" color="primary" variant="outlined"
                            value={advertisement.title}
                            disabled={loadingAdvertisement}
                            onChange={({ target }) => setAdvertisement({ ...advertisement, title: target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                        <FormControlLabel
                            control={
                                <Switch
                                    id="advertisement_enabled_switch"
                                    checked={advertisement.enabled}
                                    onChange={({ target }) => setAdvertisement({ ...advertisement, enabled: target.checked })}
                                    color="primary"
                                    disabled={loadingAdvertisement}
                                />
                            }
                            label={'Propaganda Ativa'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                        <TextField
                            id="advertisement_quantity_field"
                            className={classes.itemInput}
                            label="Veiculações contratadas"
                            size="small"
                            color="primary"
                            variant="outlined"
                            value={advertisement.quantity}
                            disabled={loadingAdvertisement}
                            onChange={({ target }) => setAdvertisement({ ...advertisement, quantity: +target.value })}
                            InputLabelProps={{
                                shrink: !!advertisement.quantity,
                            }}
                        />
                    </Grid>

                    {/* <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                        <TextField
                            className={classes.itemInput}
                            label="Preço por veiculação" size="small" color="primary" variant="outlined"
                            prefix="R$"
                            value={advertisement.price}
                            disabled={loadingAdvertisement}
                            onChange={({ target }) => setAdvertisement({ ...advertisement, price: parseFloat(target.value) })}
                            InputProps={{
                                inputComponent: NumberFormatCustom as any
                            }}
                        />
                    </Grid> */}
                    <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                        <FormControl variant="outlined" className={classes.formControl} size='small'>
                            <InputLabel id="bitrate_select_label">Bitrate</InputLabel>
                            <Select
                                id="bitrate_select_field"
                                value={advertisement?.bitrate ?? ''}
                                onChange={({ target }) => setAdvertisement({ ...advertisement, bitrate: String(target.value) })}
                                variant="outlined"
                                color="primary"
                                label="Bitrate"
                                labelId="bitrate_select_label"
                            >
                                <MenuItem key={'32k'} value={'32k'}>32k</MenuItem>
                                <MenuItem key={'40k'} value={'40k'}>40k</MenuItem>
                                <MenuItem key={'48k'} value={'48k'}>48k</MenuItem>
                                <MenuItem key={'56k'} value={'56k'}>56k</MenuItem>
                                <MenuItem key={'64k'} value={'64k'}>64k</MenuItem>
                                <MenuItem key={'80k'} value={'80k'}>80k</MenuItem>
                                <MenuItem key={'96k'} value={'96k'}>96k</MenuItem>
                                <MenuItem key={'112k'} value={'112k'}>112k</MenuItem>
                                <MenuItem key={'128k'} value={'128k'}>128k</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                        <FormControl variant="outlined" className={classes.formControl} size='small'>
                            <InputLabel htmlFor="audio_frequency_label">Frequência do áudio</InputLabel>
                            <Select
                                id="audio_frequency_field"
                                value={advertisement?.audioFrequency ?? ''}
                                onChange={({ target }) => setAdvertisement({ ...advertisement, audioFrequency: Number(target.value) })}
                                variant="outlined"
                                color="primary"
                                label="Frequência do áudio"
                                labelId="audio_frequency_label"
                            >
                                <MenuItem key={'44100'} value={'44100'}>44100 Hz</MenuItem>
                                <MenuItem key={'48000'} value={'48000'}>48000 Hz</MenuItem>

                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                        <FormControl variant="outlined" className={classes.formControl} size='small'>
                            <InputLabel id="audio_format_label">Formato do áudio</InputLabel>
                            <Select
                                id="audio_format_field"
                                value={advertisement?.audioFormat ?? ''}
                                onChange={({ target }) => setAdvertisement({ ...advertisement, audioFormat: String(target.value) })}
                                variant="outlined"
                                color="primary"
                                label="Formato do áudio"
                                labelId="audio_format_label"
                            >
                                <MenuItem key={'aac_low'} value={'aac_low'}>AAC LOW/LC</MenuItem>
                                <MenuItem key={'aac_he'} value={'aac_he'}>AAC HE</MenuItem>
                                <MenuItem key={'aac_he_v2'} value={'aac_he_v2'}>AAC HE V2</MenuItem>

                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} className={classes.dialogItemInput}>
                        <FormControl variant="outlined" className={classes.formControl} size='small'>
                            <InputLabel id="advertisement_type_label">Tipo</InputLabel>
                            <Select
                                id="advertisement_type_field"
                                value={advertisement?.type ?? ''}
                                onChange={({ target }) => {
                                    setAdvertisement({
                                        ...advertisement,
                                        type: String(target.value),
                                        quantity: target.value === 'cota' ? 100 : target.value === 'indeterminado' ? 1000000 : advertisement.quantity,
                                    });
                                }}
                                variant="outlined"
                                color="primary"
                                label="Tipo"
                                labelId="advertisement_type_label"
                            >
                                <MenuItem key={'cota'} value={'cota'}>Cota</MenuItem>
                                <MenuItem key={'indeterminado'} value={'indeterminado'}>Indeterminado</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} className={classes.dialogItemInput}>
                        <Button
                            fullWidth
                            color="primary"
                            variant="outlined"
                            disabled={loadingAdvertisement}
                            onClick={() => document.getElementById('fileselector')?.click()}
                        >
                            ESCOLHER AUDIO {
                                uploadedFileName ? `(${uploadedFileName})` : ''
                            }
                            <input
                                id="fileselector"
                                type="file"
                                accept="audio/*"
                                onChange={handleSelectAudio}
                                multiple={false}
                                style={{ display: 'none' }}
                            />
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={12} className={classes.dialogInputFile}
                        onClick={() => document.getElementById('banner-selector')?.click()}
                    >
                        <Button
                            fullWidth
                            color="primary"
                            variant="text"
                            disabled={loadingAdvertisement}
                        >
                            ESCOLHER BANNER
                            {uploadedBanner ? ` (${uploadedBanner.name})` : ''}
                            <input
                                id="banner-selector"
                                type="file"
                                accept=".png, .jpeg, .jpg"
                                onChange={handleSelectBanner}
                                multiple={false}
                                style={{ display: 'none' }}
                            />
                        </Button>
                        {(uploadedBanner || advertisement.bannerUrl) && (
                            <div className={classes.dialogBanner}>
                                <CloseIcon className={classes.closeIcon} onClick={(e) => handleRemoveBanner(e)} />
                                <img
                                    src={uploadedBanner ? URL.createObjectURL(uploadedBanner) : advertisement.bannerUrl}
                                    alt="Banner Preview"
                                    className={classes.dialogBannerImg}
                                />
                            </div>
                        )}
                    </Grid>
                    {(uploadedBanner || advertisement.bannerUrl) && (
                        <>
                            <Grid item xs={12} sm={12} className={classes.dialogItemInput}>
                                <TextField
                                    id='banner-button-text'
                                    className={classes.itemInput}
                                    label="Texto do botão (Banner)" size="small" color="primary" variant="outlined"
                                    value={advertisement.bannerButtonText}
                                    disabled={loadingAdvertisement}
                                    onChange={({ target }) => setAdvertisement({ ...advertisement, bannerButtonText: target.value })}
                                    inputProps={{ maxLength: 25 }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} className={classes.dialogItemInput}>
                                <TextField
                                    id='banner-href'
                                    className={classes.itemInput}
                                    label="Link para redirecionar (Banner)" size="small" color="primary" variant="outlined"
                                    value={advertisement.bannerHref}
                                    disabled={loadingAdvertisement}
                                    onChange={({ target }) => setAdvertisement({ ...advertisement, bannerHref: target.value })}
                                />
                            </Grid>
                        </>
                    )}
                    <Grid item xs={12} sm={12} className={classes.dialogItemInput}>
                        <TextField
                            id='advertisement-description'
                            type="textarea"
                            className={classes.itemInput}
                            label="Descrição" size="small" color="primary" variant="outlined"
                            value={advertisement.description}
                            disabled={loadingAdvertisement}
                            onChange={({ target }) => setAdvertisement({ ...advertisement, description: target.value })}
                            multiline rows={4}
                        />
                    </Grid>
                </Grid>

                <Divider size={2} />

                <DialogActions>
                    <Button onClick={handleCloseAdvertisementDialog} color="primary">
                        Cancelar
                    </Button>
                    <Button
                        onClick={saveAdvertisement} color="primary" autoFocus variant="contained"
                        disabled={loadingAdvertisement}
                        endIcon={loadingAdvertisement && (
                            <CircularProgress size={20} color="inherit" />
                        )}
                    >
                        {advertisement.uuid ? 'Salvar' : 'Criar'}
                    </Button>
                </DialogActions>
            </Dialog >
        </>
    )
}

export default Advertisement
