/* eslint-disable import-helpers/order-imports */
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import ReactQuill from 'react-quill'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import 'react-quill/dist/quill.snow.css'
import { ptBR } from 'date-fns/locale'
import Divider from '@/components/Divider'
import { NoticeType } from '@/pages/Intranet/Companies'
import ApiService from '@/services/Api'
import {
    Grid,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Dialog,
    DialogTitle,
    IconButton,
    DialogActions,
    Button,
    CircularProgress,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Typography,
    Chip
} from '@mui/material'
import {
    EditOutlined as EditIcon,
    DeleteOutline as DeleteIcon
} from '@mui/icons-material'
import useStyles from './styles'
import Markdown from 'markdown-to-jsx'
import dayjs from 'dayjs'
interface INotice extends Partial<NoticeType> {
    password?: string;
}

const Notices: React.FC = () => {
    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar()

    const [company, setCompany] = useState<NoticeType[]>([])
    const [notice, setNotice] = useState<INotice>()
    const [loading, setLoading] = useState<boolean>(false)
    const [openedUserDialog, setOpenedUserDialog] = useState<boolean>(false)

    const [openedDeleteUserDialog, setOpenedDeleteUserDialog] =
        useState<boolean>(false)
    const [loadingDeleteUser, setLoadingDeleteUser] = useState<boolean>(false)

    const openUserDialog = (notice?: NoticeType) => {
        const { startDate, endDate } = notice || {}

        setStartDate(startDate ? dayjs(startDate).toDate() : undefined)
        setEndDate(endDate ? dayjs(endDate).toDate() : undefined)

        setNotice(notice)
        setOpenedUserDialog(true)
    }

    const handleCloseUserDialog = () => {
        setOpenedUserDialog(false)
    }

    const getCompany = async () => {
        const { data } = await ApiService.get('/notices')
        setCompany(data)
    }

    const openDeleteUserDialog = async (notice: INotice) => {
        setNotice(notice)
        setOpenedDeleteUserDialog(true)
    }

    const handleDeleteUser = async () => {
        setLoadingDeleteUser(true)

        try {
            await ApiService.delete(`/notice/${notice?.uuid}`)

            setOpenedDeleteUserDialog(false)
            setNotice({})

            enqueueSnackbar('Aviso removido com sucesso.', {
                variant: 'success'
            })
        } catch (error: any) {
            enqueueSnackbar(
                error?.data || 'Ocorreu um erro ao remover o aviso.',
                {
                    variant: 'error'
                }
            )
        }

        setLoadingDeleteUser(false)

        getCompany()
    }

    const saveNotice = async () => {
        try {
            setLoading(true)

            await ApiService.post('/notice', notice)

            handleCloseUserDialog()

            enqueueSnackbar('Aviso cadastrado com sucesso.', {
                variant: 'success'
            })
        } catch (error: any) {
            enqueueSnackbar(
                error?.data || 'Ocorreu um erro ao salvar o aviso.',
                {
                    variant: 'error'
                }
            )
        }

        getCompany()

        setLoading(false)
    }

    useEffect(() => {
        getCompany()
    }, [])

    const getMessage = async (data: any) => {
        setNotice({
            ...notice,
            message: data
        })
    }

    const [startDate, setStartDate] = useState<Date | undefined>(undefined)
    const [endDate, setEndDate] = useState<Date | undefined>(undefined)

    const today = new Date()

    const handleStartDateChange = (date: any) => {
        if (date < today) {
            setStartDate(today)

            setNotice({
                ...notice,
                startDate: date
            })
        } else {
            setStartDate(date)

            setNotice({
                ...notice,
                startDate: date
            })
        }

        setEndDate(undefined)
    }

    const handleEndDateChange = (date: any) => {
        if (startDate) {
            if (date >= startDate) {
                setEndDate(date)

                setNotice({
                    ...notice,
                    endDate: date
                })
            }
        }
    }

    function truncateText(text: string, maxLength: number) {
        if (text.length <= maxLength) {
            return text
        }
        const words = text.split(' ')
        let truncatedText = ''
        for (const word of words) {
            if ((truncatedText + word).length <= maxLength) {
                truncatedText += `${word} `
            } else {
                break
            }
        }
        return `${truncatedText.trim()}...`
    }

    return (
        <>
            <Grid container>
                <Grid item xs={12} sm={12}>
                    <Typography className={classes.titleText}>
                        Avisos
                    </Typography>
                </Grid>
            </Grid>

            <Grid container justifyContent="flex-end">
                <Button
                    id="create_notice_button"
                    color="primary"
                    variant="contained"
                    onClick={() => openUserDialog()}
                >
                    Criar novo aviso
                </Button>
            </Grid>

            <Divider size={3} />
            <Grid container>
                <TableContainer component={Paper} className={classes.table}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Mensagem</TableCell>
                                <TableCell align="center">Período</TableCell>
                                <TableCell align="center">Situação</TableCell>
                                <TableCell align="center">Ações</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {company?.map((notice: any) => (
                                <TableRow key={notice.uuid}>
                                    <TableCell style={{ textAlign: 'justify', whiteSpace: 'pre-line' }}>
                                        <Markdown>
                                            {truncateText(notice.message, 300)}
                                        </Markdown>
                                    </TableCell>
                                    <TableCell align="center" width="20%">
                                        {dayjs(notice.startDate).format('DD/MM/YYYY')}{' '}
                                        à{' '}
                                        {dayjs(notice.endDate).format('DD/MM/YYYY')}
                                    </TableCell>
                                    <TableCell align="center">
                                        {notice.status === true
                                            ? (
                                                <Chip
                                                    style={{
                                                        backgroundColor:
                                                            'rgb(102, 187, 106)',
                                                        color: 'rgba(0, 0, 0, 0.87)'
                                                    }}
                                                    size="small"
                                                    label="Ativo"
                                                />
                                            )
                                            : (
                                                <Chip
                                                    color="default"
                                                    size="small"
                                                    label="Inativo"
                                                />
                                            )}
                                    </TableCell>
                                    <TableCell align="center" width="20%">
                                        <IconButton
                                            onClick={() =>
                                                openUserDialog(notice)
                                            }
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton
                                            onClick={() =>
                                                openDeleteUserDialog(notice)
                                            }
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

            <Dialog open={openedUserDialog}>
                <DialogTitle className={classes.dialogTitle}>
                    {!notice?.uuid ? 'Criando novo ' : 'Editando o '}aviso
                </DialogTitle>
                <Grid container className={classes.companyDialogContainer}>

                    <Grid
                        item
                        xs={12}
                        sm={12}
                        className={classes.dialogItemInput}
                    >
                        <FormControl
                            variant="outlined"
                            className={classes.formControl}
                        >
                            <InputLabel id="notice_show_as_label">
                                Mostrar como
                            </InputLabel>
                            <Select
                                id="notice_show_as_field"
                                labelId="notice_show_as_label"
                                label="Mostrar como"
                                value={notice?.showAs}
                                onChange={async ({ target }) => {
                                    setNotice({
                                        ...notice,
                                        showAs: target.value as string | undefined
                                    })
                                }
                                }
                                variant="outlined"
                                color="primary"
                            >
                                <MenuItem value="popup">
                                    Em uma janela (popup)
                                </MenuItem>
                                <MenuItem value="alert">
                                    No cabeçalho da página (faixa de alerta)
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid

                        item
                        xs={12}
                        sm={12}
                        className={classes.dialogItemInput}
                        style={{ marginBottom: '50px' }}
                    >
                        <ReactQuill
                            id="notice_message_field"
                            theme="snow"
                            value={notice?.message}
                            onChange={getMessage}
                            style={{ height: '150px' }}
                            modules={{
                                toolbar: [
                                    [{ size: ['small', false, 'large', 'huge'] }],
                                    [{ align: [] }],
                                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                                    ['link', 'image']
                                ]
                            }}
                        />
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sm={6}
                        className={classes.dialogItemInput}
                    >
                        <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            adapterLocale={ptBR}
                        >
                            <div id="notice_start_date_field">
                                <DatePicker
                                    label="Data Inicial"
                                    value={startDate}
                                    onChange={handleStartDateChange}
                                    disablePast
                                />
                            </div>
                        </LocalizationProvider>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sm={6}
                        className={classes.dialogItemInput}
                    >
                        <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            adapterLocale={ptBR}
                        >
                            <div id="notice_end_date_field">
                                <DatePicker
                                    label="Data Final"
                                    value={endDate}
                                    onChange={handleEndDateChange}
                                    disablePast
                                    disabled={!startDate}
                                    minDate={startDate}
                                />
                            </div>
                        </LocalizationProvider>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sm={12}
                        className={classes.dialogItemInput}
                    >
                        <FormControl
                            variant="outlined"
                            className={classes.formControl}
                        >
                            <InputLabel id="notice_status_label">
                                Situação
                            </InputLabel>
                            <Select
                                id="notice_status_field"
                                labelId="notice_status_label"
                                value={notice?.status}
                                onChange={({ target }) =>
                                    setNotice({
                                        ...notice,
                                        status: target.value
                                    })
                                }
                                variant="outlined"
                                color="primary"
                                label="Situação"
                            >
                                <MenuItem value="true">Ativo</MenuItem>
                                <MenuItem value="false">Inativo</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                <Divider size={3} />

                <DialogActions>
                    <Button
                        id="notice_dialog_cancel_button"
                        onClick={handleCloseUserDialog}
                        color="primary"
                    >
                        Cancelar
                    </Button>
                    <Button
                        id="notice_dialog_save_button"
                        onClick={saveNotice}
                        color="primary"
                        autoFocus
                        variant="contained"
                        endIcon={
                            loading && (
                                <CircularProgress size={20} color="inherit" />
                            )
                        }
                    >
                        {notice?.uuid ? 'Salvar' : 'Criar'}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                aria-labelledby="simple-dialog-title"
                open={openedDeleteUserDialog}
            >
                <DialogTitle>
                    Tem certeza que deseja remover esse aviso ?
                </DialogTitle>
                <DialogActions>
                    <Button
                        id="notice_delete_cancel_button"
                        onClick={() => setOpenedDeleteUserDialog(false)}
                        className={classes.deleteUserDialogCancelButton}
                        disabled={loadingDeleteUser}
                    >
                        Cancelar
                    </Button>
                    <Button
                        id="notice_delete_confirm_button"
                        onClick={handleDeleteUser}
                        color="primary"
                        autoFocus
                        disabled={loadingDeleteUser}
                        endIcon={
                            loadingDeleteUser && (
                                <CircularProgress size={15} color="inherit" />
                            )
                        }
                    >
                        Remover
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Notices
