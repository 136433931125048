import React, { useState } from 'react'

import Divider from '@/components/Divider'
import { Check, FileCopy, Visibility, VisibilityOff } from '@mui/icons-material'
import { IconButton, TextField } from '@mui/material'

import useStyles from './styles'

export interface CompanyInfoInputsProps {
    value: string | number | undefined
    label: string
    hidden?: boolean
    copy?: boolean
}

export default function CompanyInfoInputs({ value, label, hidden, copy }: CompanyInfoInputsProps) {
    const [visible, setVisible] = useState(false)
    const [copied, setCopied] = useState(false)
    const classes = useStyles()

    const handleCopy = (text: string | number | undefined) => {
        setCopied(true)
        navigator.clipboard.writeText(String(text))

        setTimeout(() => {
            setCopied(false)
        }, 1000)
    }

    return (
        <>
            <TextField
                id={`${label.toLowerCase().replace(/\s+/g, '_')}_field`}
                className={classes.inputField}
                variant="outlined"
                label={label}
                type={hidden && !visible ? 'password' : 'text'}
                size="small"
                focused={false}
                autoFocus={true}
                disabled
                InputProps={{
                    readOnly: true
                }}
                InputLabelProps={{
                    shrink: !(!value || value === '')
                }}
                value={value} />
            {copy && (
                <IconButton
                    id={`${label.toLowerCase().replace(/\s+/g, '_')}_copy_button`}
                    className={classes.copyButton}
                    onClick={() => handleCopy(value)}
                >
                    {copied ? <Check /> : <FileCopy />}
                </IconButton>
            )}
            {hidden && (
                <IconButton
                    id={`${label.toLowerCase().replace(/\s+/g, '_')}_visibility_button`}
                    className={classes.showHideButton}
                    onClick={() => setVisible(!visible)}
                >
                    {visible ? <Visibility /> : <VisibilityOff />}
                </IconButton>
            )}
            <Divider size={1} />
        </>
    )
}
