import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Stepper,
    Step,
    StepLabel,
    TextField,
    Grid,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    SelectChangeEvent,
    Checkbox,
    ListItemText,
    Divider,
    Typography,
    IconButton,
    Box,
    useTheme,
    CircularProgress,
} from '@mui/material';
import { debounce } from 'lodash';
import { IRadio } from '@/pages/Intranet/Apps';
import useStyles from '@/pages/Intranet/Apps/style';
import IconSelector from '../IconSearch';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EditOutlined as EditIcon, DeleteOutline as DeleteIcon, CloseOutlined as CloseIcon, } from "@mui/icons-material";
import ApiService from '@/services/Api';
import { useSnackbar } from 'notistack';

export interface FormData {
    app: {
        uuid?: string;
        backgroundColor?: string;
        mainColor?: string;
        secondaryColor?: string;
        textColor?: string;
        playerBackgroundBase64?: string;
        menuBackgroundBase64?: string;
        menuBackgroundImageKey?: string | null;
        playerBackgroundImageKey?: string | null;
        playerBackgroundImageUrl?: string;
        menuBackgroundImageUrl?: string;
        companyUuids: string[];
    };
    barButtons: {
        [companyUuid: string]: {
            icon: IconDefinition;
            url: string;
        }[];
    };
    menuButtons: {
        [companyUuid: string]: {
            textButton: string;
            icon: IconDefinition;
            url: string;
        }[];
    };
}

interface MultiStepDialogProps {
    open: boolean;
    onClose: () => void;
    loadApps: () => void;
    initialFormData: FormData;
    radios: IRadio[]
}

const debouncedUpdate = debounce((callback) => callback(), 300)

const MultiStepDialog: React.FC<MultiStepDialogProps> = ({ open, onClose, loadApps, initialFormData, radios }) => {
    const classes = useStyles();
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [formData, setFormData] = useState<FormData>(initialFormData);
    const [companies, setCompanies] = useState<string[]>(initialFormData.app.companyUuids ?? []);

    const [selectedIcon, setSelectedIcon] = useState<IconDefinition | null>(null);
    const [currentUrl, setCurrentUrl] = useState<string>("");
    const [currentText, setCurrentText] = useState<string>("");
    const [selectedCompany, setSelectedCompany] = useState<string>("");
    const [selectedIndex, setSelectedIndex] = useState<number>(-1);
    const [uploadedPlayerBackgroundImage, setUploadedPlayerBackgroundImage] = useState<File | undefined>()
    const [uploadedMenuBackgroundImage, setUploadedMenuBackgroundImage] = useState<File | undefined>()

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const steps = [
        'Informações do aplicativo',
        'Configuração dos botões',
        'Configuração do Menu',
    ];

    useEffect(() => {
        setFormData(initialFormData);
        setCompanies(initialFormData.app.companyUuids)
    }, [initialFormData])

    const handleChange = (event: SelectChangeEvent<typeof companies>) => {
        const {
            target: { value },
        } = event;
        const newValue = Array.isArray(value) ? value : value.split(",");

        if (newValue.length === 0) {
            handleRemoveBackgroundImage('playerBackgroundImage');
            handleRemoveBackgroundImage('menuBackgroundImage');
        }

        if (newValue.length === 1) {
            handleRemoveBackgroundImage('menuBackgroundImage');
        }

        setCompanies(newValue);
    };

    const handleNext = () => {
        if (validateCurrentStep()) {
            if (activeStep === 1) {
                setSelectedIcon(null);
                setSelectedCompany("");
                setCurrentUrl("");
                setCurrentText("");
            }
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setSelectedCompany("");
        setSelectedIcon(null);
        setCurrentUrl("");
    };

    const handleClose = () => {
        onClose();
        handleResetData();
        setActiveStep(0);
    };

    const validateCurrentStep = (): boolean => {
        switch (activeStep) {
            case 0:
                if (!companies || companies.length === 0) {
                    enqueueSnackbar("Selecione ao menos uma empresa.", { variant: "error" });
                    return false;
                }
                const filteredBarButtons = Object.keys(formData.barButtons)
                    .filter(companyUuid => companies.includes(companyUuid))
                    .reduce((acc, companyUuid) => {
                        acc[companyUuid] = formData.barButtons[companyUuid];
                        return acc;
                    }, {} as typeof formData.barButtons);

                setFormData((prevFormData) => ({
                    ...prevFormData,
                    app: {
                        ...prevFormData.app,
                        companyUuids: companies,
                    },
                    barButtons: filteredBarButtons
                }))
                break;

        }
        return true;
    };

    const handleSubmit = async () => {
        if (validateCurrentStep()) {
            try {
                setLoading(true);
                const { app, barButtons, menuButtons } = formData;

                const appData = {
                    ...app,
                    barButtons,
                    menuButtons
                };

                const endpoint = app.uuid ? `/app/${app.uuid}` : "/app";
                const method = app.uuid ? ApiService.put : ApiService.post;

                const response = await method(endpoint, appData);

                if (response.data) {
                    enqueueSnackbar("Aplicativo salvo com sucesso.", { variant: "success" });
                    handleResetData();
                    handleClose();
                    loadApps();
                }

            } catch (error: any) {
                console.error("Erro ao salvar aplicativo:", error);
                enqueueSnackbar(`${error.data}`, { variant: "error" });
            } finally {
                setLoading(false);
            }
        }
    };

    const updateAppColor = (colorKey: string, value: string) => {
        debouncedUpdate(() => {
            setFormData((prevFormData) => ({
                ...prevFormData,
                app: {
                    ...prevFormData.app,
                    [colorKey]: value,
                },
            }));
        });
    }

    const handleSaveIcon = (key: 'barButtons' | 'menuButtons') => {
        if (!selectedCompany || !selectedIcon || !currentUrl || (key === 'menuButtons' && !currentText)) return enqueueSnackbar("Preencha todos os campos.", { variant: "error" });

        const companyButtons = formData[key][selectedCompany] ?? [];

        const maxButtons = key === 'menuButtons' ? 10 : 5;

        if (companyButtons.length >= maxButtons && selectedIndex === -1) {
            return enqueueSnackbar(`Cada rádio pode ter no máximo ${maxButtons} botões.`, { variant: "error" });
        }

        setFormData((prevFormData) => {
            const companyButtons = prevFormData[key][selectedCompany] ?? [];

            const updatedButtons = selectedIndex === -1
                ? [...companyButtons, { icon: selectedIcon, url: currentUrl, ...(key === 'menuButtons' && { textButton: currentText }) }]
                : companyButtons.map((button, index) => (
                    index === selectedIndex
                        ? { icon: selectedIcon, url: currentUrl, ...(key === 'menuButtons' && { textButton: currentText }) }
                        : button
                ));

            return {
                ...prevFormData,
                [key]: {
                    ...prevFormData[key],
                    [selectedCompany]: updatedButtons,
                },
            };
        });

        if (key === 'menuButtons') setCurrentText("")
        setSelectedIcon(null);
        setCurrentUrl("");
        setSelectedIndex(-1);
    };

    const handleResetData = () => {
        setSelectedCompany("");
        setSelectedIcon(null);
        setCurrentUrl("");
        setCurrentText("");
        setUploadedMenuBackgroundImage(undefined);
        setUploadedPlayerBackgroundImage(undefined);
        setSelectedIndex(-1);
    }

    const handleEdit = (index: number, key: 'barButtons' | 'menuButtons') => {
        const button = formData[key][selectedCompany][index];

        if (key === 'menuButtons' && 'textButton' in button) setCurrentText(button.textButton as string);
        setSelectedIcon(button.icon);
        setCurrentUrl(button.url);
        setSelectedIndex(index);
    };

    const handleIconSelect = (icon: IconDefinition) => {
        if (!selectedCompany) return
        setSelectedIcon(icon);
    };

    const handleDelete = (index: number, key: 'barButtons' | 'menuButtons') => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [key]: {
                ...prevFormData.barButtons,
                [selectedCompany]: prevFormData.barButtons[selectedCompany].filter((_, i) => i !== index),
            },
        }));
    };

    const checkImageDimensions = (file: File): Promise<{ width: number, height: number }> => {
        return new Promise((resolve, reject) => {
            const img = new Image()
            img.onload = () => {
                resolve({
                    width: img.width,
                    height: img.height
                })
            }
            img.onerror = reject
            img.src = URL.createObjectURL(file)
        })
    }

    const handleSelectBackgroundImage = async (event: React.ChangeEvent<HTMLInputElement>, base64Key: string) => {
        try {
            const file = event.target.files?.[0]
            if (!file) return

            const validFormats = ['image/png', 'image/jpeg', 'image/jpg']
            if (!validFormats.includes(file.type)) {
                enqueueSnackbar('Formato inválido. Apenas PNG, JPEG e JPG são permitidos.', { variant: 'warning' })
                return
            }

            const { width, height } = await checkImageDimensions(file)

            if (width !== 1080 || height !== 1920) {
                enqueueSnackbar('Resolução inválida. A imagem deve ter exatamente 1080x1920 pixels.', { variant: 'warning' })
                return
            }

            const fileBase64: string = await new Promise((resolve) => {
                const reader = new FileReader()
                reader.readAsDataURL(file)

                reader.onloadend = (evt) => {
                    if (evt?.target?.readyState === FileReader.DONE) {
                        resolve(evt?.target?.result as string)
                    }
                }
            })

            setFormData((prevFormData) => ({
                ...prevFormData,
                app: {
                    ...prevFormData.app,
                    [base64Key]: String(fileBase64)
                }
            }));

            if (base64Key === 'playerBackgroundBase64') {
                setUploadedPlayerBackgroundImage(file);
            } else if (base64Key === 'menuBackgroundBase64') {
                setUploadedMenuBackgroundImage(file);
            }
        } catch (error) {
            enqueueSnackbar('Ocorreu um erro ao tentar fazer upload da imagem.', { variant: 'warning' })
        }
    }

    const handleRemoveBackgroundImage = (
        baseKey: string,
        e?: React.MouseEvent,
    ) => {
        e?.stopPropagation();

        setFormData((prevFormData) => ({
            ...prevFormData,
            app: {
                ...prevFormData.app,
                [`${baseKey}Base64`]: undefined,
                [`${baseKey}Url`]: undefined,
                [`${baseKey}Key`]: null
            }
        }));

        if (baseKey === 'playerBackgroundImage') {
            setUploadedPlayerBackgroundImage(undefined);
        } else if (baseKey === 'menuBackgroundImage') {
            setUploadedMenuBackgroundImage(undefined);
        }
    };

    const ButtonStepComponent = (key: 'barButtons' | 'menuButtons') => {
        return (
            <Grid container spacing={2}>
                <Grid
                    item
                    xs={12}
                    sm={12}
                >
                    <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        size="small"
                        style={{ width: 250 }}
                    >
                        <InputLabel id="selectedCompany">Rádio Associada</InputLabel>
                        <Select
                            id="radio_select_field"
                            labelId="selectedCompany"
                            value={selectedCompany}
                            variant="outlined"
                            onChange={(e) => setSelectedCompany(e.target.value)}
                            label="Rádio Associada"
                            renderValue={(selected) => {
                                const radio = radios.find(
                                    (r) => r.uuid === selected
                                );
                                return radio
                                    ? `${radio.name} - ${radio.address.city}`
                                    : "";
                            }
                            }
                            MenuProps={MenuProps}
                            className={classes.select}
                        >
                            {companies.map((uuid) => {
                                const radio = radios.find(
                                    (r) => r.uuid === uuid
                                );
                                return (
                                    <MenuItem
                                        key={uuid}
                                        value={uuid}
                                    >
                                        {radio?.name} - {radio?.address.city}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>

                    {selectedCompany && (
                        <IconSelector onSelect={handleIconSelect} />
                    )}

                    {selectedIcon && (
                        <>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                style={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1), marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}
                            >

                                <FontAwesomeIcon
                                    icon={selectedIcon}
                                    size="2x" />

                                {key === 'barButtons' && (
                                    <>
                                        <TextField
                                            id="bar_button_url_field"
                                            label="URL"
                                            variant="outlined"
                                            value={currentUrl}
                                            onChange={(e) => setCurrentUrl(e.target.value)}
                                            fullWidth
                                            size="small" />

                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={handleResetData}
                                            sx={{ px: theme.spacing(3) }}
                                        >
                                            Descartar
                                        </Button>

                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handleSaveIcon('barButtons')}
                                            sx={{ px: theme.spacing(3) }}
                                        >
                                            Salvar
                                        </Button>
                                    </>
                                )}

                                {key === 'menuButtons' && (
                                    <Grid sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: theme.spacing(1) }}>
                                        <TextField
                                            id="menu_button_text_field"
                                            label="Texto do Botão"
                                            variant="outlined"
                                            value={currentText}
                                            onChange={(e) => setCurrentText(e.target.value)}
                                            fullWidth
                                            size="small" />

                                        <TextField
                                            id="menu_button_url_field"
                                            label="URL"
                                            variant="outlined"
                                            value={currentUrl}
                                            onChange={(e) => setCurrentUrl(e.target.value)}
                                            fullWidth
                                            size="small" />
                                    </Grid>
                                )}
                            </Grid>
                            {key === 'menuButtons' && (
                                <Grid sx={{ display: 'flex', justifyContent: 'flex-end', gap: theme.spacing(1) }}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={handleResetData}
                                        sx={{ px: theme.spacing(3) }}
                                    >
                                        Descartar
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleSaveIcon(key)}
                                        sx={{ px: theme.spacing(3) }}
                                    >
                                        Salvar
                                    </Button>
                                </Grid>
                            )}
                        </>
                    )}

                    <Divider sx={{ mt: theme.spacing(2) }} />

                    <Grid
                        item
                        xs={12}
                        sm={12}
                    >
                        {formData[key][selectedCompany] && formData[key][selectedCompany].map((button, index) => (
                            <Box
                                key={index}
                                sx={{
                                    display: 'flex', alignItems: 'center', gap:
                                        theme.spacing(2), justifyContent: 'space-between', mt: theme.spacing(1), mb: theme.spacing(1)
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={button.icon}
                                    size="2x"
                                />

                                {key === 'barButtons' && (
                                    <Typography variant="body1">
                                        {button.url}
                                    </Typography>
                                )}

                                {key === 'menuButtons' && (
                                    <Box sx={{ width: '100%' }}>
                                        <Typography variant="body1">
                                            Texto: {'textButton' in button && button.textButton}
                                        </Typography>
                                        <Typography variant="body1">
                                            Link: {button.url}
                                        </Typography>
                                    </Box>
                                )}

                                <Grid sx={{ display: 'flex' }}>
                                    <IconButton onClick={() => handleEdit(index, key)}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton onClick={() => handleDelete(index, key)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Grid>
                            </Box>
                        ))}
                    </Grid>

                </Grid>
            </Grid>
        )
    }

    const renderStepContent = (step: number) => {
        switch (step) {
            case 0:
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="background_color_field"
                                fullWidth
                                label="Cor de fundo"
                                size="small"
                                variant="outlined"
                                type="color"
                                value={formData.app.backgroundColor}
                                onChange={(e) => {
                                    updateAppColor("backgroundColor", e.target.value)
                                }}

                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="main_color_field"
                                fullWidth
                                label="Cor principal"
                                size="small"
                                variant="outlined"
                                type="color"
                                value={formData.app.mainColor}
                                onChange={(e) => {
                                    updateAppColor("mainColor", e.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="secondary_color_field"
                                fullWidth
                                label="Cor secundária"
                                size="small"
                                variant="outlined"
                                type="color"
                                value={formData.app.secondaryColor}
                                onChange={(e) => {
                                    updateAppColor("secondaryColor", e.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="text_color_field"
                                fullWidth
                                label="Cor de texto"
                                size="small"
                                variant="outlined"
                                type="color"
                                value={formData.app.textColor}
                                onChange={(e) => {
                                    updateAppColor("textColor", e.target.value)
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                        >
                            <FormControl
                                variant="outlined"
                                className={classes.formControl}
                                size="small"
                                style={{ width: 250 }}
                            >
                                <InputLabel id="associatedRadios">Rádios Associadas</InputLabel>
                                <Select
                                    id="associated_radios_select"
                                    labelId="associatedRadios"
                                    multiple
                                    value={companies}
                                    variant="outlined"
                                    onChange={handleChange}
                                    label="Rádios Associadas"
                                    renderValue={(selected) =>
                                        selected
                                            .map((uuid) => {
                                                const radio = radios.find(
                                                    (r) => r.uuid === uuid
                                                );
                                                return radio
                                                    ? `${radio.name} - ${radio.address.city}`
                                                    : "";
                                            })
                                            .join(", ")
                                    }
                                    MenuProps={MenuProps}
                                    className={classes.select}
                                >
                                    {radios.map((radio) => (
                                        <MenuItem
                                            key={radio.uuid}
                                            value={radio.uuid}
                                        >
                                            <Checkbox
                                                id={`radio_checkbox_${radio.uuid}`}
                                                checked={
                                                    companies.indexOf(
                                                        radio.uuid
                                                    ) > -1
                                                }
                                            />
                                            <ListItemText
                                                primary={`${radio.name} - ${radio.address.city}`}
                                            />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        {companies.length > 0 && (
                            <Grid
                                item
                                xs={12}
                                sm={12}
                            >
                                <div
                                    className={classes.dialogInputFile}
                                    onClick={() => document.getElementById('player-background-selector')?.click()}
                                >
                                    <Button
                                        fullWidth
                                        color="primary"
                                        variant="text"
                                    >
                                        Imagem de fundo do player
                                        {uploadedPlayerBackgroundImage ? ` (${uploadedPlayerBackgroundImage.name})` : ''}
                                        <input
                                            id="player-background-selector"
                                            type="file"
                                            accept=".png, .jpeg, .jpg"
                                            onChange={(e) => handleSelectBackgroundImage(e, 'playerBackgroundBase64')}
                                            multiple={false}
                                            style={{ display: 'none' }}
                                        />
                                    </Button>
                                    {(uploadedPlayerBackgroundImage || formData.app?.playerBackgroundImageUrl) && (
                                        <div className={classes.dialogLogo}>
                                            <CloseIcon
                                                className={classes.closeIcon}
                                                onClick={(e) => handleRemoveBackgroundImage(
                                                    'playerBackgroundImage',
                                                    e,
                                                )} />
                                            <img
                                                src={uploadedPlayerBackgroundImage ? URL.createObjectURL(uploadedPlayerBackgroundImage) : formData.app?.playerBackgroundImageUrl}
                                                alt="Player Background Image Preview"
                                                className={classes.dialogLogoImg}
                                            />
                                        </div>
                                    )}
                                </div>
                            </Grid>
                        )}

                        {companies.length > 1 && (
                            <Grid
                                item
                                xs={12}
                                sm={12}
                            >
                                <div
                                    className={classes.dialogInputFile}
                                    onClick={() => document.getElementById('menu-background-selector')?.click()}
                                >
                                    <Button
                                        fullWidth
                                        color="primary"
                                        variant="text"

                                    >
                                        Imagem de fundo do menu
                                        {uploadedMenuBackgroundImage ? ` (${uploadedMenuBackgroundImage.name})` : ''}
                                        <input
                                            id="menu-background-selector"
                                            type="file"
                                            accept=".png, .jpeg, .jpg"
                                            onChange={(e) => handleSelectBackgroundImage(
                                                e,
                                                'menuBackgroundBase64',
                                            )}
                                            multiple={false}
                                            style={{ display: 'none' }}
                                        />
                                    </Button>
                                    {(uploadedMenuBackgroundImage || formData.app?.menuBackgroundImageUrl) && (
                                        <div className={classes.dialogLogo}>
                                            <CloseIcon
                                                className={classes.closeIcon}
                                                onClick={(e) => handleRemoveBackgroundImage(
                                                    'menuBackgroundImage',
                                                    e,
                                                )} />
                                            <img
                                                src={uploadedMenuBackgroundImage ? URL.createObjectURL(uploadedMenuBackgroundImage) : formData.app?.menuBackgroundImageUrl}
                                                alt="Menu Background Image Preview"
                                                className={classes.dialogLogoImg}
                                            />
                                        </div>
                                    )}
                                </div>
                            </Grid>
                        )}
                    </Grid>
                );
            case 1:
                return ButtonStepComponent('barButtons')
            case 2:
                return ButtonStepComponent('menuButtons')
            default:
                return 'Etapa desconhecida';
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>
                Formulário de {formData.app.uuid ? 'Edição' : 'Cadastro'}
            </DialogTitle>

            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            <DialogContent>
                {renderStepContent(activeStep)}
            </DialogContent>

            <DialogActions>
                {activeStep === 0 && (
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                )}
                {activeStep !== 0 && (
                    <Button onClick={handleBack} color="primary" disabled={loading}>
                        Voltar
                    </Button>
                )}
                {activeStep === steps.length - 1 ? (
                    <Button
                        onClick={handleSubmit}
                        color="primary"
                        variant="contained"
                        disabled={loading}
                        endIcon={loading && (
                            <CircularProgress size={20} color="inherit" />
                        )}
                    >
                        Salvar
                    </Button>
                ) : (
                    <Button
                        onClick={handleNext}
                        color="primary"
                        variant="contained"
                    >
                        Próximo
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default MultiStepDialog;