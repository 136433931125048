import axios from 'axios'
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'

import statesAndCities from '@/assets/statesAndCities.json'
import Divider from '@/components/Divider'
import { maskCpf, maskPhone, unMask } from '@/components/Mask'
import Pagination from '@/components/Table/TablePagination'
import { useFetch } from '@/hooks/useFetch'
import ApiService from '@/services/Api'
import { StreamingPartner } from '@/types/streamingPartner'
import { streamingPartners } from '@/utils/streamingPartners'
import {
    EditOutlined as EditIcon,
    AspectRatioOutlined as AccessCompanyIcon,
    DeleteOutlineOutlined as DeleteIcon,
    CloseOutlined as CloseIcon,
} from '@mui/icons-material'
import WifiTetheringIcon from '@mui/icons-material/WifiTethering'
import {
    Grid,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Button,
    TextField,
    Dialog,
    DialogTitle,
    DialogActions,
    CircularProgress,
    IconButton,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
    Checkbox,
    FormControlLabel,
    Link,
    Box,
    useTheme
} from '@mui/material'

import useStyles from './styles'
import CitySearchSelect from '@/components/CitySearchSelect'
import Separator from '@/components/Divider'

export type ProductType = 'codec' | 'metrics' | 'intranet'
export type CodecRoleType = 'studio' | 'communicator'
export type IntranetRoleType = 'admin'

export type UserType = {
    uuid: string
    companyUuid: string
    name: string
    email: string
    password: string
    product: ProductType
    role: CodecRoleType | IntranetRoleType
    isBetaTester: boolean
    createdAt: Date
    updatedAt: Date
}

type IcecastType = {
    uuid?: string;
    companyUuid?: string;
    streamId?: number;
    currentBitrate?: number;
    currentFrequency?: number;
    currentFormat?: string;
    currentCodec?: string;
    dockerImage?: string;
    streamServerURL?: string;
    streamServerPort?: string;
    mountpointUsername?: string;
    mountpointPassword?: string;
    mountpointPort?: string;
    mountpointName?: string;
    adminUsername?: string;
    adminPassword?: string;
    streamingPartner?: StreamingPartner
    createdAt?: Date;
    updatedAt?: Date;
};

export type NoticeType = {
    uuid?: string
    userUuid?: string
    message?: string
    startDate?: Date | undefined
    endDate?: Date | undefined
    showAs?: string
    status?: boolean | unknown
    createdAt?: Date
    updatedAt?: Date
}

type AddressType = {
    uuid?: string
    country?: string
    state?: string
    city?: string
    neighborhood?: string
    street?: string
    number?: string
    complement?: string
    latitude?: string | null
    longitude?: string | null
    zipcode?: string
}

export type CompanyType = {
    uuid?: string
    streamId?: number
    addressUuid?: string

    frequency?: string
    externalIcecastUrl?: string

    name?: string
    corporateName?: string
    cnpjCpf?: string
    fistel?: string
    email?: string
    payerEmail?: string
    phoneNumber?: string
    payerPhoneNumber?: string
    logoBase64?: string | null
    logoUrl?: string
    site?: string;
    twitterLink?: string;
    instagramLink?: string;
    facebookLink?: string;
    youtubeLink?: string;
    publicWhatsappPhoneNumber?: string;
    aboutContent?: string
    mobileAppUuid?: string;

    streamPassword?: string

    createdAt?: Date
    updatedAt?: Date
    deletedAt?: Date

    adstreamEnabled?: boolean
    whatsappEnabled?: boolean
    youtubeEnabled?: boolean
    concurrenceEnabled?: boolean
    enabled?: boolean

    anydeskPassword?: string
    anydeskCode?: string
    supportPhonenumber?: string

    address?: AddressType
    users?: UserType[]
    icecast?: IcecastType
    notices?: NoticeType[]
    segments?: string[]

    streamingServerUrl?: string

    liveStreamingChannelId?: string
    liveStreamingChannelName?: string

    coveredCities?: string[]
    mapsZoom?: number
}

const Companies: React.FC = () => {
    const classes = useStyles()
    const theme = useTheme()
    const { enqueueSnackbar } = useSnackbar()

    const [companies, setCompanies] = useState<CompanyType[]>([])
    const [company, setCompany] = useState<CompanyType>({})
    const [icecast, setIcecast] = useState<IcecastType>()
    const [companyUuid, setCompanyUuid] = useState<string>('')
    const [companyName, setCompanyName] = useState<string>('')
    const [openedCompanyDialog, setOpenedCompanyDialog] = useState<boolean>(false)
    const [openedIcecastDialog, setOpenedIcecastDialog] = useState<boolean>(false)
    const [openedCompanyDelete, setOpenedCompanyDelete] = useState<boolean>(false)
    const [loadingCompany, setLoadingCompany] = useState<boolean>(false)
    const [deletingCompany, setDeletingCompany] = useState<boolean>(false)
    const [searchInput, setSearchInput] = useState<string>('')
    const [filterEnabled, setFilterEnabledInput] = useState<boolean>(false)
    const [uploadedLogo, setUploadedLogo] = useState<File | undefined>()
    const [filterStatus, setFilterStatus] = useState<'all' | 'active' | 'inactive'>('all')
    const [filterType, setFilterType] = useState<'clients' | 'competitors' | undefined>(undefined)
    const [selectedPartner, setSelectedPartner] = useState('')
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0) // Voltar para a primeira página ao alterar o número de linhas
    }

    const normalizeText = (text: string) =>
        text.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/\s+/g, '')

    const filteredCompanies = companies.filter((company) => {
        if (!filterEnabled && !company?.enabled) return false

        if (filterType === 'competitors' && company.adstreamEnabled) return false
        if (filterType === 'clients' && !company.adstreamEnabled) return false

        if (filterStatus === 'active' && !company.adstreamEnabled) return false
        if (filterStatus === 'inactive' && company.adstreamEnabled) return false
        if (selectedPartner !== '' && company.icecast?.streamingPartner !== selectedPartner) return false

        if (searchInput) {
            const search = normalizeText(searchInput)
            const nameMatch = normalizeText(company.name || '').includes(search)
            const streamIdMatch = normalizeText(String(company.streamId) || '').includes(search)
            const cityMatch = normalizeText(company.address?.city || '').includes(search)

            return nameMatch || streamIdMatch || cityMatch
        }

        return true
    })

    const toggleFilterStatus = (status: 'active' | 'inactive') => {
        setFilterStatus((prevStatus) => (prevStatus === status ? 'all' : status))
    }

    const toggleFilterType = (type: 'clients' | 'competitors') => {
        setFilterType((prevType) => (prevType === type ? undefined : type))
    }

    // Dados paginados
    const paginatedCompanies = filteredCompanies.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

    const formatMapping = {
        aac_low: 'AAC LOW/LC',
        aac_he: 'AAC HE',
        aac_he_v2: 'AAC HE V2',
        aac_lc: 'AAC LC'
    }

    const openCompanyDelete = (uuid: string, name: string) => {
        setCompanyUuid(uuid)
        setCompanyName(name)
        setOpenedCompanyDelete(true)
    }

    const openCompanyIcecast = (icecast?: IcecastType, company?: CompanyType) => {
        if (icecast) {
            setIcecast({
                ...icecast,
                companyUuid: company?.uuid ?? '',
                streamId: company?.streamId ?? 0,
                streamServerURL: icecast.streamServerURL ?? '',
                adminUsername: icecast.adminUsername ?? '',
                adminPassword: icecast.adminPassword ?? '',
                streamServerPort: icecast.streamServerPort ?? '',
                mountpointName: icecast.mountpointName ?? '',
                streamingPartner: icecast.streamingPartner ?? 'Loopert'
            })
        } else {
            setIcecast({
                uuid: '',
                companyUuid: company?.uuid ?? '',
                streamId: company?.streamId ?? 0,
                dockerImage: '',
                streamServerURL: '',
                streamServerPort: '',
                mountpointUsername: '',
                mountpointPassword: '',
                mountpointPort: '',
                mountpointName: '',
                adminUsername: '',
                adminPassword: '',
                streamingPartner: 'Loopert',
                createdAt: new Date(),
                updatedAt: new Date()
            })
        }

        setOpenedIcecastDialog(true)
    }

    const openCompanyDialog = (company?: CompanyType) => {
        setCompany(company
            ? { ...company }
            : {
                adstreamEnabled: false,
                whatsappEnabled: false,
                enabled: false,
                address: {
                    state: '',
                    city: '',
                    neighborhood: '',
                    street: '',
                    number: '',
                    complement: '',
                    zipcode: '',
                    latitude: null,
                    longitude: null,
                },
                coveredCities: []
            })
        setOpenedCompanyDialog(true)
        setUploadedLogo(undefined)
    }

    const handleCloseCompanyDialog = () => {
        setOpenedCompanyDialog(false)
    }

    const getCompanies = async () => {
        const { data } = await ApiService.get('/company')
        setCompanies(data)
        setIcecast(data[0].icecast)
    }

    const deleteCompany = async (companyUuid: string) => {
        setDeletingCompany(true)
        setCompanyUuid('')
        setCompanyName('')
        try {
            await ApiService.delete(`/company/${companyUuid}`)
            await getCompanies()

            setOpenedCompanyDelete(false)
            setDeletingCompany(false)
        } catch (error) {
            enqueueSnackbar(String(error || 'Ocorreu um erro ao tentar confirmar o codigo.'), { variant: 'error' })
            setOpenedCompanyDelete(false)
            setDeletingCompany(false)
        }
    }

    const saveCompany = async () => {
        if (!company?.coveredCities?.length) {
            enqueueSnackbar('É necessário selecionar ao menos uma cidade de atuação.', {
                variant: 'warning'
            })
            return
        }

        try {
            setLoadingCompany(true)
            await ApiService.post('/company', {
                latitude: Number(company?.address?.latitude),
                longitude: Number(company?.address?.longitude),
                ...company
            })

            handleCloseCompanyDialog()
        } catch (e) {
            enqueueSnackbar('Ocorreu um erro ao criar a rádio.', {
                variant: 'error'
            })
        }

        getCompanies()
        setLoadingCompany(false)
    }


    const handleZipCodeInput = async (zipcode: string) => {
        try {
            setCompany({ ...company, address: { ...company?.address, zipcode: zipcode } })

            if (zipcode.length === 8) {
                const { data } = await axios.get(`https://viacep.com.br/ws/${zipcode}/json/`)
                if (data) {
                    const state = _.find(statesAndCities, { initials: data.uf })?.name

                    setCompany({
                        ...company,
                        address: {
                            ...company?.address,
                            zipcode: zipcode,
                            street: data.logradouro || company.address?.street,
                            neighborhood: data.bairro || company.address?.neighborhood,
                            state: state || company.address?.state,
                            city: data.localidade || company.address?.city,
                            latitude: company.address?.latitude || null,
                            longitude: company.address?.longitude || null
                        }
                    })
                }
            }
        } catch (e) {
        }
    }

    useFetch<CompanyType[]>(
        '/company',
        (data: CompanyType[]) => {
            setCompanies(data)
        }
    )

    const saveOrUpdateIcecast = async (): Promise<void> => {
        if (!icecast) {
            enqueueSnackbar('Nenhum dado de Icecast fornecido.', { variant: 'error' })
            return
        }

        // Verificação única para os campos obrigatórios
        const requiredFields: { key: keyof typeof icecast, label: string }[] = [
            { key: 'streamServerURL', label: 'Stream Server URL' },
            { key: 'adminUsername', label: 'Admin Username' },
            { key: 'adminPassword', label: 'Admin Password' },
            { key: 'streamServerPort', label: 'Stream Server Port' },
            { key: 'mountpointName', label: 'Mountpoint Name' },
            { key: 'streamingPartner', label: 'Streaming Partner' }
        ]

        for (const field of requiredFields) {
            const value = icecast[field.key]
            if (value === undefined || value === null || value === '') {
                enqueueSnackbar(`O campo ${field.label} é obrigatório e não pode estar vazio.`, { variant: 'error' })
                return
            }
        }

        try {
            await ApiService.post('/icecast', icecast)
            enqueueSnackbar('Icecast salvo com sucesso!', { variant: 'success' })
            getCompanies()
            setOpenedIcecastDialog(false)
        } catch (e) {
            console.error('Erro ao salvar o Icecast:', e)
            enqueueSnackbar('Ocorreu um erro ao salvar o Icecast.', { variant: 'error' })
        }
    }

    const handleSelectLogo = async (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
            const file = event.target.files?.[0]
            if (!file) return

            const validFormats = ['image/png', 'image/jpeg', 'image/jpg']
            if (!validFormats.includes(file.type)) {
                enqueueSnackbar('Formato inválido. Apenas PNG, JPEG e JPG são permitidos.', { variant: 'warning' })
                return
            }

            const fileBase64: string = await new Promise((resolve) => {
                const reader = new FileReader()
                reader.readAsDataURL(file)

                reader.onloadend = (evt) => {
                    if (evt?.target?.readyState === FileReader.DONE) {
                        resolve(evt?.target?.result as string)
                    }
                }
            })

            setCompany({ ...company, logoBase64: String(fileBase64) })
            setUploadedLogo(file)
        } catch (error) {
            enqueueSnackbar('Ocorreu um erro ao tentar fazer upload da imagem.', { variant: 'warning' })
        }
    }

    const handleRemoveLogo = (e: React.MouseEvent) => {
        e.stopPropagation()
        setUploadedLogo(undefined)
        setCompany({
            ...company,
            logoBase64: null,
            logoUrl: undefined
        })
    }

    const handleCityChange = (value: string[]) => {
        setCompany({
            ...company,
            coveredCities: value as string[],
        });
    };

    const handleStarClick = (value: string) => {
        const updatedCities = [value, ...company?.coveredCities?.filter((city) => city !== value) || []];
        setCompany({
            ...company,
            coveredCities: updatedCities,
        });
    };


    return (
        <>
            <Grid container justifyContent="space-between">
                <Grid>
                    <TextField
                        id="company_search_field"
                        label="Buscar empresa" size="small" color="primary" variant="outlined"
                        value={searchInput}
                        onChange={({ target }) => setSearchInput(target.value)}
                    />

                    <FormControlLabel
                        className={classes.filterEnabledCheckbox}
                        control={
                            <Checkbox
                                id="filter_enabled_checkbox"
                                checked={filterEnabled}
                                onChange={({ target }) => setFilterEnabledInput(target.checked)}
                                color="default"
                            />
                        }
                        label={'Filtrar inativos'}
                    />
                </Grid>
                <Button
                    id="create_radio_button"
                    color="primary"
                    variant="contained"
                    onClick={() => openCompanyDialog()}
                >
                    Criar nova rádio
                </Button>
            </Grid>

            <Divider size={3} />

            <Grid display="flex" gap={2} marginBottom={2} flexWrap="wrap">
                <Button
                    id="filter_all_button"
                    variant={filterStatus === 'all' && filterType === undefined && selectedPartner === '' ? 'contained' : 'outlined'}
                    onClick={() => {
                        setFilterStatus('all')
                        setFilterType(undefined)
                        setSelectedPartner('')
                    }}
                    size='small'
                >
                    Todos
                </Button>
                <Button
                    id="filter_active_button"
                    variant={filterStatus === 'active' ? 'contained' : 'outlined'}
                    onClick={() => toggleFilterStatus('active')}
                    size='small'
                >
                    Ativas
                </Button>
                <Button
                    id="filter_inactive_button"
                    variant={filterStatus === 'inactive' ? 'contained' : 'outlined'}
                    onClick={() => toggleFilterStatus('inactive')}
                    size='small'
                >
                    Desativadas
                </Button>
                <Button
                    id="filter_competitors_button"
                    variant={filterType === 'competitors' ? 'contained' : 'outlined'}
                    onClick={() => toggleFilterType('competitors')}
                    size='small'
                >
                    Concorrentes
                </Button>
                <Button
                    id="filter_clients_button"
                    variant={filterType === 'clients' ? 'contained' : 'outlined'}
                    onClick={() => toggleFilterType('clients')}
                    size='small'
                >
                    Clientes
                </Button>
                <FormControl variant="outlined" className={classes.formControlSelect} size='small'>
                    <InputLabel id="streaming_partner_filter_label">Parceiro de streaming</InputLabel>
                    <Select
                        id="streaming_partner_filter_field"
                        value={selectedPartner}
                        defaultValue=''
                        onChange={(e) =>
                            setSelectedPartner(e.target.value)
                        }
                        variant="outlined"
                        color="primary"
                        label="Parceiro de streaming"
                        labelId="streaming_partner_filter_label"
                    >
                        <MenuItem value=''>Todos</MenuItem>
                        {streamingPartners.map((partner) => (
                            <MenuItem key={partner.value} value={partner.value}>{partner.label}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

            </Grid>
            <Grid container>
                <TableContainer component={Paper} className={classes.table}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Nome</TableCell>
                                <TableCell align="center">ID do adstream/icecast</TableCell>
                                <TableCell align="center">Frequência</TableCell>
                                <TableCell align="center">Bitrate Icecast</TableCell>
                                <TableCell align="center">Frequência Icecast</TableCell>
                                <TableCell align="center">Format</TableCell>
                                <TableCell align="center">Cidade</TableCell>
                                <TableCell align="center">Adstream Ativo</TableCell>
                                <TableCell align="center">Ações</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                paginatedCompanies.map((company) => (
                                    <TableRow key={company.uuid}>
                                        <TableCell component="th" scope="row">{company.name}</TableCell>
                                        <TableCell align="center">{company?.streamId}</TableCell>
                                        <TableCell align="center">{company?.frequency}</TableCell>
                                        <TableCell align="center">{company?.icecast?.currentBitrate && `${company?.icecast?.currentBitrate}k`}</TableCell>
                                        <TableCell align="center">{company?.icecast?.currentFrequency}</TableCell>
                                        <TableCell align="center">{formatMapping[company?.icecast?.currentFormat as keyof typeof formatMapping] || company?.icecast?.currentFormat || ''}</TableCell>
                                        <TableCell align="center">{company?.address?.city}</TableCell>
                                        <TableCell align="center">{company?.adstreamEnabled ? 'Ativo' : 'Desativado'}</TableCell>
                                        <TableCell align="center">
                                            <IconButton
                                                onClick={() => openCompanyIcecast(company?.icecast, company)}
                                                title={'Icecast'}
                                            >
                                                <WifiTetheringIcon />
                                            </IconButton>

                                            <IconButton
                                                onClick={() => openCompanyDelete(company?.uuid || '', company?.name || 'esta rádio')}
                                                title='Deletar'
                                            >
                                                <DeleteIcon />
                                            </IconButton>

                                            <IconButton
                                                onClick={() => openCompanyDialog(company)}
                                                title='Editar'
                                            >
                                                <EditIcon />
                                            </IconButton>

                                            <Link href={`/companies/${company.uuid}`}>
                                                <IconButton title='Acessar'>
                                                    <AccessCompanyIcon />
                                                </IconButton>
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                    <Pagination
                        count={filteredCompanies.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableContainer>
            </Grid>

            <Dialog open={openedCompanyDialog}>
                <DialogTitle className={classes.dialogTitle}>
                    {!company?.uuid ? 'Criando nova ' : 'Editando a '}rádio
                </DialogTitle>
                <Grid container className={classes.companyDialogContainer} >
                    <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                        <TextField
                            id="company_name_field"
                            className={classes.itemInput}
                            label="Nome" size="small" color="primary" variant="outlined"
                            value={company.name}
                            onChange={({ target }) => setCompany({ ...company, name: target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                        <TextField
                            id="company_frequency_field"
                            className={classes.itemInput}
                            label="Frequência" size="small" color="primary" variant="outlined"
                            value={company.frequency}
                            onChange={({ target }) => setCompany({ ...company, frequency: target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="adstream_enabled_checkbox"
                                    checked={company.adstreamEnabled}
                                    onChange={({ target }) => setCompany({ ...company, adstreamEnabled: target.checked })}
                                    color="default"
                                />
                            }
                            label={'Adstream Ativo'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="whatsapp_enabled_checkbox"
                                    checked={company.whatsappEnabled}
                                    onChange={({ target }) => setCompany({ ...company, whatsappEnabled: target.checked })}
                                    color="default"
                                />
                            }
                            label={'Whatsapp Ativo'}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="youtube_enabled_checkbox"
                                    checked={company.youtubeEnabled}
                                    onChange={({ target }) => setCompany({ ...company, youtubeEnabled: target.checked })}
                                    color="default"
                                />
                            }
                            label={'Youtube Ativo'}
                        />
                    </Grid>

                    {company.youtubeEnabled && (
                        <>
                            <Grid item xs={12} sm={12} className={classes.dialogItemInput}>
                                <TextField
                                    id="channel_name_field"
                                    className={classes.itemInput}
                                    label="Nome do canal" size="small" color="primary" variant="outlined"
                                    value={company.liveStreamingChannelName}
                                    onChange={({ target }) => setCompany({ ...company, liveStreamingChannelName: target.value })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} className={classes.dialogItemInput}>
                                <TextField
                                    id="channel_id_field"
                                    className={classes.itemInput}
                                    label="ID do canal" size="small" color="primary" variant="outlined"
                                    value={company.liveStreamingChannelId}
                                    onChange={({ target }) => setCompany({ ...company, liveStreamingChannelId: target.value })}
                                />
                            </Grid>
                        </>

                    )}

                    <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="radio_enabled_checkbox"
                                    checked={company.enabled}
                                    onChange={({ target }) => setCompany({ ...company, enabled: target.checked })}
                                    color="default"
                                />
                            }
                            label={'Radio Ativa'}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} className={classes.dialogItemInput}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="concurrence_enabled_checkbox"
                                    checked={company.concurrenceEnabled}
                                    onChange={({ target }) => setCompany({ ...company, concurrenceEnabled: target.checked })}
                                    color="default"
                                />
                            }
                            label={'Concorrência Pública Ativa'}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                        <FormControl className={classes.formControl}>
                            <InputLabel className={classes.selectInput} htmlFor='segments_select'>Segmentos</InputLabel>
                            <Select
                                onChange={({ target }) => {
                                    setCompany({
                                        ...company,
                                        segments: typeof target.value === 'string'
                                            ? [target.value]
                                            : target.value as string[]
                                    }
                                    )
                                }}
                                id="segments_select"
                                label="Segmentos"
                                variant="outlined"
                                color="primary"
                                multiple
                                className={classes.select}
                                value={company?.segments || []}
                                MenuProps={
                                    {
                                        PaperProps: {
                                            style: {
                                                maxHeight: 400
                                            }
                                        }
                                    }
                                }
                            >
                                <MenuItem value="Adulto Classic">Adulto Classic</MenuItem>
                                <MenuItem value="Adulto Contemporâneo">Adulto Contemporâneo</MenuItem>
                                <MenuItem value="Eclética">Eclética</MenuItem>
                                <MenuItem value="Esportes">Esportes</MenuItem>
                                <MenuItem value="Entrevistas">Entrevistas</MenuItem>
                                <MenuItem value="Flashback">Flashback</MenuItem>
                                <MenuItem value="Gospel Católica">Gospel Católica</MenuItem>
                                <MenuItem value="Gospel Evangélica">Gospel Evangélica</MenuItem>
                                <MenuItem value="Jornalismo (News)">Jornalismo (News)</MenuItem>
                                <MenuItem value="MPB">MPB</MenuItem>
                                <MenuItem value="Pop ">Pop </MenuItem>
                                <MenuItem value="Pop-Rock">Pop-Rock</MenuItem>
                                <MenuItem value="Sertanejo">Sertanejo</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={12} className={classes.dialogInputFile}
                        onClick={() => document.getElementById('logo-selector')?.click()}
                    >
                        <Button
                            fullWidth
                            color="primary"
                            variant="text"
                        >
                            ESCOLHER LOGO
                            {uploadedLogo ? ` (${uploadedLogo.name})` : ''}
                            <input
                                id="logo-selector"
                                type="file"
                                accept=".png, .jpeg, .jpg"
                                onChange={handleSelectLogo}
                                multiple={false}
                                style={{ display: 'none' }}
                            />
                        </Button>
                        {(uploadedLogo || company?.logoUrl) && (
                            <div className={classes.dialogLogo}>
                                <CloseIcon className={classes.closeIcon} onClick={(e) => handleRemoveLogo(e)} />
                                <img
                                    src={uploadedLogo ? URL.createObjectURL(uploadedLogo) : `${company?.logoUrl}?t=${Date.now()}`}
                                    alt="Logo Preview"
                                    className={classes.dialogLogoImg}
                                />
                            </div>
                        )}
                    </Grid>

                    <Grid item xs={12} sm={12} className={classes.dialogItemInput}>
                        <TextField
                            id="external_icecast_url_field"
                            className={classes.itemInput}
                            label="Link externo do Icecast (Concorrencia) (Ex. /status.xml)" size="small" color="primary" variant="outlined"
                            value={company.externalIcecastUrl}
                            onChange={({ target }) => setCompany({ ...company, externalIcecastUrl: target.value })}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                        <TextField
                            id="corporate_name_field"
                            className={classes.itemInput}
                            label="Razão Social" size="small" color="primary" variant="outlined"
                            value={company.corporateName}
                            onChange={({ target }) => setCompany({ ...company, corporateName: target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                        <TextField
                            id="cnpj_cpf_field"
                            className={classes.itemInput}
                            label="CNPJ/CPF" size="small" color="primary" variant="outlined"
                            value={maskCpf(company.cnpjCpf)}
                            onChange={({ target }) => setCompany({ ...company, cnpjCpf: unMask(target.value) })}
                            inputProps={{ maxLength: 18 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                        <TextField
                            id="fistel_field"
                            className={classes.itemInput}
                            label="Fistel" size="small" color="primary" variant="outlined"
                            value={company.fistel}
                            onChange={({ target }) => setCompany({ ...company, fistel: target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                        <TextField
                            id="email_field"
                            className={classes.itemInput}
                            label="Email" size="small" color="primary" variant="outlined"
                            value={company.email}
                            onChange={({ target }) => setCompany({ ...company, email: target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                        <TextField
                            id="payer_email_field"
                            className={classes.itemInput}
                            label="Email do financeiro" size="small" color="primary" variant="outlined"
                            value={company.payerEmail}
                            onChange={({ target }) => setCompany({ ...company, payerEmail: target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                        <TextField
                            id="phone_number_field"
                            className={classes.itemInput}
                            label="Número de telefone" size="small" color="primary" variant="outlined"
                            value={maskPhone(company.phoneNumber)}
                            onChange={({ target }) => setCompany({ ...company, phoneNumber: unMask(target.value) })}
                            inputProps={{ maxLength: 16 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                        <TextField
                            id="payer_phone_field"
                            className={classes.itemInput}
                            label="Número de telefone do financeiro" size="small" color="primary" variant="outlined"
                            value={maskPhone(company.payerPhoneNumber)}
                            onChange={({ target }) => setCompany({ ...company, payerPhoneNumber: unMask(target.value) })}
                            inputProps={{ maxLength: 16 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                        <TextField
                            id="support_phone_field"
                            className={classes.itemInput}
                            label="Número de telefone do suporte" size="small" color="primary" variant="outlined"
                            value={maskPhone(company?.supportPhonenumber)}
                            onChange={({ target }) => setCompany({ ...company, supportPhonenumber: unMask(target.value) })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                        <TextField
                            id="anydesk_code_field"
                            className={classes.itemInput}
                            label="Código do Anydesk" size="small" color="primary" variant="outlined"
                            value={company?.anydeskCode}
                            onChange={({ target }) => setCompany({ ...company, anydeskCode: target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                        <TextField
                            id="anydesk_password_field"
                            className={classes.itemInput}
                            label="Senha do Anydesk" size="small" color="primary" variant="outlined"
                            value={company?.anydeskPassword}
                            onChange={({ target }) => setCompany({ ...company, anydeskPassword: target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                        <TextField
                            id="site_field"
                            className={classes.itemInput}
                            label="Site" size="small" color="primary" variant="outlined"
                            value={company.site}
                            placeholder="loopert.com"
                            onChange={({ target }) => setCompany({ ...company, site: target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                        <TextField
                            id="twitter_field"
                            className={classes.itemInput}
                            label="Twitter" size="small" color="primary" variant="outlined"
                            value={company.twitterLink}
                            placeholder="Loopert Inc"
                            onChange={({ target }) => setCompany({ ...company, twitterLink: target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                        <TextField
                            id="instagram_field"
                            className={classes.itemInput}
                            label="Instagram" size="small" color="primary" variant="outlined"
                            value={company.instagramLink}
                            placeholder="Loopert Inc"
                            onChange={({ target }) => setCompany({ ...company, instagramLink: target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                        <TextField
                            id="facebook_field"
                            className={classes.itemInput}
                            label="Facebook" size="small" color="primary" variant="outlined"
                            value={company.facebookLink}
                            placeholder="Loopert Inc"
                            onChange={({ target }) => setCompany({ ...company, facebookLink: target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                        <TextField
                            id="youtube_field"
                            className={classes.itemInput}
                            label="YouTube" size="small" color="primary" variant="outlined"
                            value={company.youtubeLink}
                            placeholder="Loopert Inc"
                            onChange={({ target }) => setCompany({ ...company, youtubeLink: target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                        <TextField
                            id="whatsapp_field"
                            className={classes.itemInput}
                            label="Whatsapp" size="small" color="primary" variant="outlined"
                            value={maskPhone(company?.publicWhatsappPhoneNumber)}
                            onChange={({ target }) => setCompany({ ...company, publicWhatsappPhoneNumber: unMask(target.value) })}
                        />
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={12} className={classes.dialogItemInput}>
                    <TextField
                        id="about_content_field"
                        type="textarea"
                        className={classes.itemInput}
                        label="Sobre a rádio" size="small" color="primary" variant="outlined"
                        value={company.aboutContent}
                        onChange={({ target }) => setCompany({ ...company, aboutContent: target.value })}
                        multiline rows={4}
                    />
                </Grid>

                <Grid container >
                    <Box sx={{ width: '100%', height: '1px', backgroundColor: '#ccc', margin: '10px' }} />

                    <Grid item xs={12} sm={12} className={classes.dialogItemInput}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="zipcode_field"
                                className={classes.itemInput}
                                label="CEP" size="small" color="primary" variant="outlined"
                                value={company?.address?.zipcode}
                                placeholder="99999999"
                                onChange={({ target }) => handleZipCodeInput(String(target.value))}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                        <FormControl variant="outlined" className={classes.formControl} size='small'>
                            <InputLabel id="state_select_label">Estado</InputLabel>
                            <Select
                                id="state_select_field"
                                value={company?.address?.state}
                                onChange={({ target }) => setCompany({ ...company, address: { ...company?.address, city: '', state: String(target.value) } })}
                                variant="outlined"
                                color="primary"
                                label="Estado"
                                labelId="state_select_label"
                            >
                                {
                                    statesAndCities.map((state: any) => (
                                        <MenuItem key={state.name} value={state.name}>{state.name}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                        {
                            company?.address?.state && (
                                <FormControl variant="outlined" className={classes.formControl} size='small'>
                                    <InputLabel id="city_select_label">Cidade</InputLabel>
                                    <Select
                                        id="city_select_field"
                                        value={company?.address?.city}
                                        onChange={({ target }) => setCompany({ ...company, address: { ...company?.address, city: String(target.value) } })}
                                        variant="outlined"
                                        color="primary"
                                        label="Cidade"
                                        labelId="city_select_label"
                                    >
                                        {
                                            statesAndCities.filter(state => state.name === company?.address?.state)[0].cities.map((cityName: string) => (
                                                <MenuItem key={cityName} value={cityName}>{cityName}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            )
                        }
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                        <TextField
                            id="neighborhood_field"
                            className={classes.itemInput}
                            label="Bairro" size="small" color="primary" variant="outlined"
                            value={company?.address?.neighborhood}
                            onChange={({ target }) => setCompany({ ...company, address: { ...company?.address, neighborhood: target.value } })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                        <TextField
                            id="street_field"
                            className={classes.itemInput}
                            label="Rua" size="small" color="primary" variant="outlined"
                            value={company?.address?.street}
                            onChange={({ target }) => setCompany({ ...company, address: { ...company?.address, street: target.value } })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                        <TextField
                            id="number_field"
                            className={classes.itemInput}
                            label="Número" size="small" color="primary" variant="outlined"
                            value={company?.address?.number}
                            onChange={({ target }) => setCompany({ ...company, address: { ...company?.address, number: target.value } })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                        <TextField
                            id="complement_field"
                            className={classes.itemInput}
                            label="Complemento" size="small" color="primary" variant="outlined"
                            value={company?.address?.complement}
                            onChange={({ target }) => setCompany({ ...company, address: { ...company?.address, complement: target.value } })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                        <TextField
                            id="latitude_field"
                            className={classes.itemInput}
                            label="Latitude" size="small" color="primary" variant="outlined"
                            value={company?.address?.latitude}
                            onChange={({ target }) => setCompany({ ...company, address: { ...company?.address, latitude: target.value } })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                        <TextField
                            id="longitude_field"
                            className={classes.itemInput}
                            label="Longitude" size="small" color="primary" variant="outlined"
                            value={company?.address?.longitude}
                            onChange={({ target }) => setCompany({ ...company, address: { ...company?.address, longitude: target.value } })}
                        />
                    </Grid>

                    <Box sx={{ width: '100%', height: '1px', backgroundColor: '#ccc', margin: '10px' }} />

                    <Grid item xs={12} sm={12} className={classes.dialogItemInput}>
                        <FormControl variant="outlined" className={classes.formControl} size='small'>
                            <CitySearchSelect
                                value={company?.coveredCities || []}
                                onChange={(value) => handleCityChange(value)}
                                onStarCity={handleStarClick}
                            />

                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} className={classes.dialogItemInput}>
                        <TextField
                            id="maps_zoom_field"
                            className={classes.itemInput}
                            type='number'
                            label="Zoom" size="small" color="primary" variant="outlined"
                            value={company?.mapsZoom}
                            onChange={({ target }) => setCompany({ ...company, mapsZoom: Number(target.value) })}
                            inputProps={{
                                type: 'number',
                                step: .1,
                                min: 0,
                                max: 12
                            }}
                            defaultValue={4}
                        />
                    </Grid>
                </Grid>

                <Divider size={2} />

                <DialogActions>
                    <Button
                        id="cancel_company_button"
                        onClick={handleCloseCompanyDialog}
                        color="primary"
                    >
                        Cancelar
                    </Button>
                    <Button
                        id="save_company_button"
                        onClick={saveCompany}
                        color="primary"
                        autoFocus
                        variant="contained"
                        disabled={loadingCompany}
                        endIcon={loadingCompany && (
                            <CircularProgress size={20} color="inherit" />
                        )}
                    >
                        {company.uuid ? 'Salvar' : 'Criar'}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openedIcecastDialog} onClose={() => setOpenedIcecastDialog(false)}>
                <DialogTitle className={classes.dialogTitle}>
                    {!icecast?.uuid ? 'Cadastrando dados do ' : 'Editando dados do '} Icecast
                </DialogTitle>
                <Grid container className={classes.companyDialogContainer}>

                    <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                        <TextField
                            id="stream_server_url_field"
                            className={classes.itemInput}
                            label="Stream Server URL"
                            value={icecast?.streamServerURL}
                            onChange={({ target }) => setIcecast({ ...icecast, streamServerURL: target.value })}
                            size="small"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                        <TextField
                            id="stream_server_port_field"
                            className={classes.itemInput}
                            label="Stream Server Port"
                            value={icecast?.streamServerPort}
                            onChange={({ target }) => setIcecast({ ...icecast, streamServerPort: target.value })}
                            size="small"
                            variant="outlined"
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                        <TextField
                            id="admin_username_field"
                            className={classes.itemInput}
                            label="Admin Username"
                            value={icecast?.adminUsername}
                            onChange={({ target }) => setIcecast({ ...icecast, adminUsername: target.value })}
                            size="small"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                        <TextField
                            id="admin_password_field"
                            className={classes.itemInput}
                            label="Admin Password"
                            value={icecast?.adminPassword}
                            onChange={({ target }) => setIcecast({ ...icecast, adminPassword: target.value })}
                            size="small"
                            variant="outlined"
                            type="password"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                        <TextField
                            id="mountpoint_name_field"
                            className={classes.itemInput}
                            label="Mountpoint Name"
                            value={icecast?.mountpointName}
                            onChange={({ target }) => setIcecast({ ...icecast, mountpointName: target.value })}
                            size="small"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                        <FormControl variant="outlined" className={classes.formControl} size='small'
                        >
                            <InputLabel id="streaming_partner_label">Parceiro de streaming</InputLabel>
                            <Select
                                id="streaming_partner_field"
                                value={icecast?.streamingPartner}
                                onChange={({ target }) => setIcecast({ ...icecast, streamingPartner: target.value as StreamingPartner })}
                                variant="outlined"
                                color="primary"
                                label="Parceiro de streaming"
                                labelId="streaming_partner_label"
                            >
                                {streamingPartners.map((partner) => (
                                    <MenuItem key={partner.value} value={partner.value}>{partner.label}</MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                        <FormControl variant="outlined" className={classes.formControl} size='small'>
                            <InputLabel id="icecast_bitrate_label">Bitrate</InputLabel>
                            <Select
                                id="icecast_bitrate_field"
                                value={icecast?.currentBitrate ?? ''}
                                onChange={({ target }) => setIcecast({ ...icecast, currentBitrate: Number(target.value) })}
                                variant="outlined"
                                color="primary"
                                label="Bitrate"
                                labelId="icecast_bitrate_label"
                            >
                                <MenuItem key={'32'} value={'32'}>32k</MenuItem>
                                <MenuItem key={'40'} value={'40'}>40k</MenuItem>
                                <MenuItem key={'48'} value={'48'}>48k</MenuItem>
                                <MenuItem key={'56'} value={'56'}>56k</MenuItem>
                                <MenuItem key={'64'} value={'64'}>64k</MenuItem>
                                <MenuItem key={'80'} value={'80'}>80k</MenuItem>
                                <MenuItem key={'96'} value={'96'}>96k</MenuItem>
                                <MenuItem key={'112'} value={'112'}>112k</MenuItem>
                                <MenuItem key={'128'} value={'128'}>128k</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                        <FormControl variant="outlined" className={classes.formControl} size='small'>
                            <InputLabel id="icecast_audio_format_label">Formato do áudio</InputLabel>
                            <Select
                                id="icecast_audio_format_field"
                                value={icecast?.currentFormat ?? ''}
                                onChange={({ target }) => setIcecast({ ...icecast, currentFormat: String(target.value) })}
                                variant="outlined"
                                color="primary"
                                label="Formato do áudio"
                                labelId="icecast_audio_format_label"
                            >
                                <MenuItem key={'aac_low'} value={'aac_low'}>AAC LOW/LC</MenuItem>
                                <MenuItem key={'aac_he'} value={'aac_he'}>AAC HE</MenuItem>
                                <MenuItem key={'aac_he_v2'} value={'aac_he_v2'}>AAC HE V2</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                        <FormControl variant="outlined" className={classes.formControl} size='small'>
                            <InputLabel id="icecast_audio_frequency_label">Frequência do áudio</InputLabel>
                            <Select
                                id="icecast_audio_frequency_field"
                                value={icecast?.currentFrequency ?? ''}
                                onChange={({ target }) => setIcecast({ ...icecast, currentFrequency: Number(target.value) })}
                                variant="outlined"
                                color="primary"
                                label="Frequência do áudio"
                                labelId="icecast_audio_frequency_label"
                            >
                                <MenuItem key={'44100'} value={'44100'}>44100 Hz</MenuItem>
                                <MenuItem key={'48000'} value={'48000'}>48000 Hz</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Divider size={2} />
                <Grid container justifyContent="flex-end" spacing={2} >
                    <Grid item>
                        <Button
                            id="icecast_dialog_cancel_button"
                            onClick={() => setOpenedIcecastDialog(false)}
                            color="primary"
                        >
                            Cancelar
                        </Button>
                    </Grid>
                    <Grid item>
                        <DialogActions>
                            <Button
                                id="icecast_dialog_save_button"
                                onClick={saveOrUpdateIcecast}
                                color="primary"
                                autoFocus
                                variant="contained"
                            >
                                {icecast?.uuid ? 'Salvar Alterações' : 'Cadastrar'}
                            </Button>
                        </DialogActions>
                    </Grid>
                </Grid>
            </Dialog>

            <Dialog open={openedCompanyDelete}>
                <DialogTitle className={classes.dialogTitle}>
                    Tem certeza que deseja remover <b>{companyName}</b>?
                </DialogTitle>

                <DialogActions>
                    <Button
                        id="company_delete_cancel_button"
                        onClick={() => { setOpenedCompanyDelete(false) }}
                        color="primary"
                    >
                        Cancelar
                    </Button>
                    <Button
                        id="company_delete_confirm_button"
                        onClick={() => deleteCompany(companyUuid)}
                        color="primary"
                        autoFocus
                        variant="contained"
                        endIcon={deletingCompany && (
                            <CircularProgress size={20} color="inherit" />
                        )}
                    >
                        Deletar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Companies
