import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import Divider from '@/components/Divider'
import { useFetch } from '@/hooks/useFetch'
import useDidMount from '@/hooks/useMount'
import Infos from '@/pages/Intranet/Company/Infos'
// import Users from '@/pages/Intranet/Company/Users'
import ApiService from '@/services/Api'
import {
    Divider as DividerLine
} from '@mui/material'

import { CompanyType } from '../Companies'
import Connections from './Connections'

const Company: React.FC = () => {
    const params: any = useParams()
    const { companyUuid } = params

    // eslint-disable-next-line no-unused-vars
    const [company, setCompany] = useState<CompanyType>({} as CompanyType)

    const getCompany = async () => {
        const { data } = await ApiService.get(`/company/${companyUuid}`) // CompanyType

        setCompany(data)
    }

    useFetch<CompanyType>(
        `/company/${companyUuid}`,
        (data: CompanyType) => {
            setCompany(data)
        }
    )

    useDidMount(() => {
        getCompany()
    })

    return (
        <>
            <Infos />

            <Divider size={4} />
            <DividerLine />
            <Divider size={2} />

            {/* <Users /> */}

            <Divider size={4} />
            <DividerLine />
            <Divider size={2} />

            <Connections />
        </>
    )
}

export default Company
