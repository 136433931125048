import { useSnackbar } from 'notistack'
import React, { FormEvent, useState } from 'react'

import ApiService from '@/services/Api'
import {
    Grid,
    Button,
    TextField,
    CircularProgress,
    Card,
    CardContent
} from '@mui/material'

import useStyles from './styles'

// import { AuthContext } from '@/contexts/auth'

type Credentials = {
    email: string,
    password: string
}

const Audience: React.FC = () => {
    const classes = useStyles()

    const { enqueueSnackbar } = useSnackbar()

    // const authContext = useContext(AuthContext)

    const [credentials, setCredentials] = useState<Credentials>({} as Credentials)
    const [loadingCredentials, setLoadingCredentials] = useState<boolean>(false)

    const handleLogin = async (event: FormEvent<HTMLFormElement> | null = null) => {
        if (event) event.preventDefault()
        if (loadingCredentials) return

        setLoadingCredentials(true)

        try {
            const { data } = await ApiService.post('/auth/login', credentials)

            localStorage.setItem('token', data.token)

            window.location.href = '/'
        } catch (e) {
            setLoadingCredentials(false)

            enqueueSnackbar('Ocorreu um erro ao fazer login', {
                variant: 'error'
            })
        }
    }

    return (
        <Grid container className={classes.container} alignContent="center" justifyContent="center">
            <Card className={classes.cardContainer}>
                <CardContent className={classes.cardContent}>
                    <form onSubmit={handleLogin}>
                        <Grid item className={classes.input}>
                            <TextField
                                id="login_email_field"
                                label="Email"
                                variant="outlined"
                                size="small"
                                fullWidth
                                onChange={
                                    ({ target }) => setCredentials({ ...credentials, email: target.value })
                                }
                            />
                        </Grid>

                        <Grid item className={classes.input}>
                            <TextField
                                id="login_password_field"
                                label="Senha"
                                variant="outlined"
                                size="small"
                                fullWidth
                                type="password"
                                onChange={
                                    ({ target }) => setCredentials({ ...credentials, password: target.value })
                                }
                            />
                        </Grid>

                        <Grid item>
                            <Button
                                id="login_submit_button"
                                fullWidth
                                type="submit"
                                className={classes.signinButton}
                                color="primary"
                                variant="contained"
                                disabled={loadingCredentials}
                                endIcon={loadingCredentials && (
                                    <CircularProgress size={20} color="inherit" />
                                )}
                            >
                                Entrar
                            </Button>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </Grid>
    )
}

export default Audience
